<template>
  <div class="invisible-scrollbar">
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Location Mapping
        </div>
        <v-card-text>
          <div>
            <v-card elevation="10" style="padding: 2%; margin-bottom: 2%">
              <v-row>
                <v-col cols="12" md="6" class="mt-3">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-on:keyup.enter="searchDataLocationMapping(search)"
                    v-model="search"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="6" class="mt-1" style="text-align: end">
                  <v-btn :color="'blue'" class="ma-2 white--text">
                    Search

                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissionsPage.create"
                    color="green"
                    class="ma-2 white--text"
                  >
                    Create
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="ListData"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.towerName }}</td>
                    <td class="header-table-css">{{ item.projectName }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.createTime) }}
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 150px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="
                          getDataLocationMappingListByTowerID(item.towerID)
                        "
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="
                          inactiveLocationMappingByTowerID(
                            item.towerID,
                            item.active
                          )
                        "
                      >
                        <v-icon dark>mdi-autorenew </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    v-model="itemsPerPage"
                    label="10/page"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog
      v-model="LocationMappingDialog"
      persistent
      max-width="800px"
      scrollable
    >
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Edit Location Mapping : {{ towerName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" style="margin-top: 10px">
                <template>
                  <v-expansion-panels focusable v-model="addLocationOpen">
                    <v-expansion-panel v-for="(item, i) in 1" :key="i">
                      <v-expansion-panel-header>
                        <v-icon style="display: block"
                          >mdi-plus-box-multiple</v-icon
                        >Add Location
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="mt-4">
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              label="Location Name*"
                              v-model="locationName"
                              dense
                              outlined
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              :items="ProjectList"
                              label="ProjectSite"
                              item-text="projectName"
                              item-value="projectID"
                              v-model="projectID"
                              required
                              clearable
                              outlined
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              :items="ZoneList"
                              label="Zone"
                              item-text="zoneName"
                              item-value="zoneID"
                              v-model="zoneID"
                              required
                              clearable
                              outlined
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              :items="FloorList"
                              label="Floor"
                              item-text="floorName"
                              item-value="floorID"
                              v-model="floorID"
                              required
                              clearable
                              outlined
                              dense
                            ></v-select>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            style="display: flex; justify-content: center"
                          >
                            <v-hover v-slot="{ hover }">
                              <v-btn
                                color="green"
                                class="ma-2 white--text"
                                :elevation="hover ? 10 : 2"
                                :class="{ 'on-hover': hover }"
                                @click="addLocation()"
                              >
                                Add Location
                                <v-icon right> mdi-plus </v-icon>
                              </v-btn>
                            </v-hover>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            :headers="headersLocationMapping"
            :items="locationMappingList"
            hide-default-footer
            style="color: #da1818de"
            :items-per-page="itemsLocationPerPage"
            :page.sync="pageLocation"
            single-line
            hide-details
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="header-table-css-center" style="color: black">
                  {{ (pageLocation - 1) * 10 + (index + 1) }}
                </td>
                <td class="header-table-css-center" style="color: black">
                  {{ item.locationID }}
                </td>
                <td class="header-table-css-center" style="color: black">
                  {{ item.locationName }}
                </td>
                <td
                  class="header-table-css"
                  style="
                    display: flex;

                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <v-btn
                    :color="GetColor(item.active)"
                    rounded
                    class="text-capitalize"
                    depressed
                    style="width: 110px"
                  >
                    <span :style="{ color: GetColorText(item.active) }">{{
                      item.active == true ? "Active" : "InActive"
                    }}</span>
                  </v-btn>
                  <!-- <v-switch inset v-model="item.active"></v-switch> -->
                </td>
                <td style="text-align: center">
                  <v-btn fab dark small color="blue">
                    <v-icon dark @click="mapdata(item)"
                      >mdi-square-edit-outline</v-icon
                    >
                  </v-btn>
                  <v-btn
                    v-if="item.locationID == 0"
                    style="margin-left: 10px"
                    fab
                    dark
                    small
                    color="red"
                  >
                    <v-icon dark @click="DeleteLocation(item)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center mt-3">
            <v-pagination
              v-model="pageLocation"
              :length="lengthLocation"
              :total-visible="5"
              @input="ChangeLocationPage(pageLocation)"
            ></v-pagination>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="updateDataLocationMapping()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="LocationMappingDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogZoneMap" persistent max-width="800px">
      <v-card>
        <v-card-title style="background-color: grey; color: white">
          <span class="text-h5">Edit Map Location : {{ locationName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8" class="mt-4">
                <v-text-field
                  label="Location Name"
                  v-model="locationName"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-select
                  :items="ProjectList"
                  label="ProjectSite"
                  item-text="projectName"
                  item-value="projectID"
                  v-model="projectID"
                  required
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                style="display: flex; justify-content: space-around"
              >
                <v-switch
                  hide-details
                  inset
                  :label="`Active`"
                  v-model="activeMap"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="ZoneList"
                  label="Zone"
                  item-text="zoneName"
                  item-value="zoneID"
                  v-model="zoneID"
                  required
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="FloorList"
                  label="Floor"
                  item-text="floorName"
                  item-value="floorID"
                  v-model="floorID"
                  required
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="DoneMap(locationID)"
              >
                Done
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                color="gray"
                @click="dialogZoneMap = false"
              >
                Close
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "LocationMapping",
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      permissionsPage: [],
      page: 1,
      itemsPerPage: 10,
      search: "",
      ListData: [],
      pageCount: 0,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      LocationMappingDialog: false,

      tempTowerID: 0,
      towerName: "",
      locationMappingList: [],
      ProjectList: [],
      ZoneList: [],
      FloorList: [],
      TowerList: [],

      projectID: 0,
      towerID: 0,
      zoneID: 0,
      floorID: 0,
      locationID: 0,
      locationName: "",
      activeMap: false,

      dialogZoneMap: false,
      pageLocation: 1,
      lengthLocation: 1,
      totalPage: 10,
      itemsLocationPerPage: 10,
      addLocationOpen: [0],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "TowerName",
          align: "left",
          sortable: false,
        },
        {
          text: "ProjectSite",
          align: "left",
          sortable: false,
        },
        {
          text: "CreateDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersLocationMapping() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "LocationID",
          sortable: false,
          align: "center",
        },
        {
          text: "Location Name",
          sortable: false,
          align: "center",
        },
        // {
        //   text: "ProjectID",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "TowerID",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "ZoneID",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "FloorID",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  async mounted() {
    let self = this;
    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      self.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    self.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);
    await self.getDataLocationMappingList();
    await self.getDataProjectList();
    await self.getDataTowerList();
    await self.getDataZoneList();
  },

  methods: {
    getDataLocationMappingList() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: self.search == "" ? null : self.search,
      };
      axios
        .post(`${self.url}LocationMapping/getDataLocationMappingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    searchDataLocationMapping(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
      };
      axios
        .post(`${self.url}LocationMapping/getDataLocationMappingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    inactiveLocationMappingByTowerID(towerID, active) {
      let self = this;
      this.LoadingDialog = true;
      let temp = {
        towerID: towerID,
      };
      Swal.fire({
        title: active ? "InActive" : "Active",
        text: active
          ? "Do you want to Inactive Location ?"
          : "Do you want to Active Location ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              `${self.url}LocationMapping/inactiveLocationMappingByTowerID`,
              temp
            )
            .then(function (response) {
              if (response.data.status == 0) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                // self.LoadingDialog = false;
                self.getDataLocationMappingList();
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
            })
            .catch(function (error) {
              self.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          // self.LoadingDialog = false;
        } else {
          self.LoadingDialog = false;
        }
        // self.LoadingDialog = false;
      });
    },

    getDataLocationMappingListByTowerID(id) {
      let self = this;
      self.LoadingDialog = true;
      self.towerID = id;
      let temp = {
        towerID: id,
      };
      axios
        .post(`${self.url}LocationMapping/getDataLocationMappingListByID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // console.log(response.data.data);
            self.MappingData(response.data.data);
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    updateDataLocationMapping() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        towerID: self.tempTowerID,
        listData: self.LocationListPage,
      };
      axios
        .post(`${self.url}LocationMapping/updateDataLocationMapping`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LocationMappingDialog = false;
            self.LoadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          // self.LocationMappingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getDataProjectList() {
      let self = this;
      axios
        .get(`${self.url}LocationMapping/getDataProjectList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProjectList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getDataTowerList() {
      let self = this;
      axios
        .get(`${self.url}LocationMapping/getDataTowerList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TowerList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getDataZoneList() {
      let self = this;
      axios
        .get(`${self.url}LocationMapping/getDataZoneList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ZoneList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getDataFloorByTowerID() {
      let self = this;
      let temp = {
        towerID: self.towerID,
      };
      axios
        .post(`${self.url}LocationMapping/getDataFloorByTowerID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.FloorList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    MappingData(data) {
      let self = this;
      // console.log(data);
      self.tempTowerID = data.towerID;
      self.towerName = data.towerName;
      self.locationMappingList = data.locationMappingList;
      self.pageLocation = 1;
      self.LocationListPage = data.locationMappingList;
      self.lengthLocation = Math.ceil(data.locationMappingList.length / 10);
      self.getDataFloorByTowerID();
      self.LocationMappingDialog = true;
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == "" ? null : self.search,
      };
      axios
        .post(`${self.url}LocationMapping/getDataLocationMappingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == "" ? null : self.search,
      };
      axios
        .post(`${self.url}LocationMapping/getDataLocationMappingList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ListData = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    DeleteLocation(data) {
      let self = this;
      let index = self.locationMappingList.indexOf(data);
      self.locationMappingList.splice(index, 1);
    },

    ChangeLocationPage(value) {
      let that = this;
      that.locationMappingList = that.paginate(
        that.LocationListPage,
        10,
        value
      );
    },
    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },

    mapdata(data) {
      let that = this;
      // console.log(data);
      // that.indexlistFloor = index;
      // that.flooridSelect = data.floorid;
      // that.floorNameShow = data.floorName;
      // that.tree = data.zoneList;
      that.projectID = data.projectID;
      that.zoneID = data.zoneID;
      that.floorID = data.floorID;
      that.towerID = data.towerID;
      that.locationID = data.locationID;
      that.locationName = data.locationName;
      that.activeMap = data.active;
      that.dialogZoneMap = true;
    },

    DoneMap(locationID) {
      let that = this;
      // console.log(locationID);
      that.locationMappingList.find(
        (x) => x.locationID == locationID
      ).locationName = that.locationName;
      that.locationMappingList.find(
        (x) => x.locationID == locationID
      ).projectID = that.projectID;
      that.locationMappingList.find((x) => x.locationID == locationID).active =
        that.activeMap;
      that.locationMappingList.find((x) => x.locationID == locationID).zoneID =
        that.zoneID;
      that.locationMappingList.find((x) => x.locationID == locationID).floorID =
        that.floorID;

      that.locationName = "";
      that.locationID = 0;
      that.zoneID = 0;
      that.floorID = 0;
      that.projectID = 0;

      that.dialogZoneMap = false;
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    addLocation() {
      let that = this;
      let data = {
        locationID: 0,
        locationName: that.locationName,
        zoneID: that.zoneID,
        floorID: that.floorID,
        projectID: that.projectID,
        active: true,
      };

      that.LocationListPage.push(data);
      that.locationName = "";
      that.locationID = 0;
      that.zoneID = 0;
      that.floorID = 0;
      that.projectID = 0;

      that.pageLocation = 1;
      that.locationMappingList = that.paginate(that.LocationListPage, 10, 1);
      that.lengthLocation = Math.ceil(that.LocationListPage.length / 10);

      that.addLocationOpen = [0];
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}
.header-table-css-center {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.backgroundR {
  /* background-color: red; */
  background: red;
}
.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
