<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-card variant="outlined">
                <v-card-title style="font-weight: bolder">
                  <v-row>
                    <v-col cols="12" md="3"
                      >Welcome back
                      <span class="ml-5" style="color: #22577a">{{
                        username
                      }}</span></v-col
                    >
                    <v-col cols="12" md="6"
                      ><span class="ml-5">{{ CurrentDatetime }}</span></v-col
                    >
                    <v-col cols="12" md="3" style="text-align: right"
                      ><span style="color: #776b5d"
                        >Visitor Management</span
                      ></v-col
                    >
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row style="margin-top: 2%">
            <v-col cols="12" md="3" sm="3">
              <v-card class="mt-3 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="blue"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                  height="70"
                >
                  <v-card-title class="text-h5" style="color: white">
                    Visitor Today
                    <v-icon class="ml-3" style="color: white"
                      >mdi-account-multiple</v-icon
                    >
                    :
                    {{ VisitorToday?.total }}
                  </v-card-title>
                </v-sheet>

                <v-card-subtitle style="color: #2196f3">
                  <v-progress-linear
                    striped
                    v-model="VisitorTodayProgressbar"
                    color="blue"
                    height="20"
                  ></v-progress-linear>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Still Remain :
                      <span style="font-size: large">{{
                        VisitorToday?.stillremain
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Exited :
                      <span style="font-size: large">{{
                        VisitorToday?.exited
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-card class="mt-3 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="#ffae1f"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                  height="70"
                >
                  <v-card-title class="text-h5" style="color: #ffffff">
                    Total Visitor
                    <v-icon class="ml-3" color="white"
                      >mdi-account-multiple</v-icon
                    >
                    :
                    {{ AddCommasNumber(TotalVisitor?.total) }}
                  </v-card-title>
                </v-sheet>

                <v-card-subtitle style="color: #ffae1f">
                  <v-progress-linear
                    striped
                    v-model="TotalVisitorProgressbar"
                    color="#ffae1f"
                    height="20"
                  ></v-progress-linear>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Invite :
                      <span style="font-size: large; color: #ffae1f">{{
                        AddCommasNumber(TotalVisitor?.invite)
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Walk In :
                      <span style="font-size: large; color: #ffae1f">{{
                        AddCommasNumber(TotalVisitor?.walkin)
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" md="3" sm="3">
              <v-card class="mt-3 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="pink"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                  height="70"
                >
                  <v-card-title class="text-h5" style="color: white">
                    Visitor Entry Today
                    <v-icon class="ml-3" style="color: white"
                      >mdi-account-multiple</v-icon
                    >
                    :
                    {{ VisitorEntryToday?.total }}
                  </v-card-title>
                </v-sheet>

                <v-card-subtitle style="color: #E27278">
                  <v-progress-linear
                    striped
                    v-model="VisitorEntryTodayProgressbar"
                    color="pink"
                    height="20"
                  ></v-progress-linear>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Invite :
                      <span style="font-size: large;">{{
                        VisitorEntry?.invite
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Walk In :
                      <span style="font-size: large;">{{
                        VisitorEntry?.walkin
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col> -->
            <v-col cols="12" md="3" sm="3"></v-col>
            <v-col cols="12" md="3" sm="3"></v-col>
          </v-row>
        </v-card-text>
      </div>

      <div>
        <v-row style="padding: 20px">
          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="#B0A695"
                elevation="12"
                max-width="calc(100% -  32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Time Period Visitor
                </v-card-title>
              </v-sheet>
              <apexchart
                v-if="show"
                type="bar"
                height="500"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-card>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="#B0A695"
                elevation="12"
                max-width="calc(100% -  32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Latest Entry Visitor : Past 7 Days
                </v-card-title>
              </v-sheet>
              <apexchart
                v-if="show"
                type="bar"
                height="500"
                :options="chartOptions2"
                :series="series2"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="padding: 20px">
          <v-col cols="12" md="12" sm="12">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="#B0A695"
                elevation="12"
                max-width="calc(100% - 32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Transaction Visitor
                </v-card-title>
              </v-sheet>
              <!-- <v-card-title> Latest Entry </v-card-title> -->
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">No</th>
                      <th class="text-center">Datetime</th>
                      <th class="text-center">VisitorName</th>
                      <th class="text-center">VisitorType</th>
                      <th class="text-center">ContactTenant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in DataList" :key="item.id">
                      <td class="text-center">
                        {{ index + 1 }}
                      </td>
                      <td class="text-center">
                        {{ ChangeFormatDate(item.transacDatetime) }}
                      </td>
                      <td class="text-center">{{ item.name }}</td>
                      <td class="text-center">{{ item.visitorType }}</td>
                      <td class="text-center">{{ item.inviteTenantID }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "DashboardVisitor",
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      show: false,
      items: [10, 15, 20, 50, 100],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      VisitorToday: [],
      TotalVisitor: [],
      VisitorEntry: [],

      existTower: [],
      existTowerEntry: [],
      existTowerExit: [],
      responseTime: [],
      responseDate: [],
      username: localStorage.getItem("Username"),
      TotalVisitorProgressbar: 0,
      VisitorTodayProgressbar: 0,
      VisitorEntryTodayProgressbar: 0,
      Member: 0,
      Visitor: 0,
      dateNow: "",
      CurrentDatetime: "",
      ConfigTerminal: [],
      DataList: [],

      labels1: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value1: [200, 675, 410, 390, 310, 460, 250, 240],
      chartOptionsdonut: {
        labels: ["Apple", "Mango", "Orange", "Watermelon"],
      },
      seriesdonut: [44, 55, 41, 17, 15],
      series: [
        {
          name: "Entry",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //14
        },
        {
          name: "Exit",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //14
        },
      ],
      series2: [
        {
          name: "Entry",
          data: [0, 0, 0, 0, 0, 0, 0], //7
        },
        {
          name: "Exit",
          data: [0, 0, 0, 0, 0, 0, 0], //7
        },
      ],

      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        colors: ["#008000", "#FF0000"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: "Time Period",
          },
          categories: [
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
          ],
        },
        yaxis: {
          title: {
            text: "User",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " times";
            },
          },
        },
      },
      chartOptions2: {
        chart: {
          type: "bar",
          height: 350,
        },
        colors: ["#008000", "#FF0000"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: "Datetime",
          },
          categories: [
            "2024-02-15",
            "2024-02-16",
            "2024-02-17",
            "2024-02-18",
            "2024-02-19",
            "2024-02-20",
            "2024-02-21",
          ],
        },
        yaxis: {
          title: {
            text: "User",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " times";
            },
          },
        },
      },
    };
  },

  async mounted() {
    let that = this;
    // await that.getTotalDashboardVisitor(1);
    setInterval(() => {
      that.getCurrentDate();
    }, 1000);
  },

  methods: {
    AddCommasNumber(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    refreshDashboard() {
      let that = this;
      var refresh = setInterval(() => {
        if (localStorage.getItem("page") == "Dashboard Visitor") {
          that.getTotalDashboardVisitor(2);
        } else {
          clearInterval(refresh);
        }
      }, 10000);
    },

    getTotalDashboardVisitor(data) {
      let self = this;
      if (data == 1) {
        self.LoadingDialog = true;
      }
      axios
        .get(`${self.url}Dashboard/getTotalDashboardVisitor`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listDataVisitor;
            self.VisitorToday = response.data.data.visitorToday;
            self.TotalVisitor = response.data.data.visitorTotal;
            // self.VisitorEntry = response.data.data.visitorEntryToday;
            self.TotalVisitorProgressbar =
              (response.data.data.visitorToday.stillremain /
                (response.data.data.visitorToday.stillremain +
                  response.data.data.visitorToday.exited)) *
              100;
            self.VisitorTodayProgressbar =
              (response.data.data.visitorTotal.invite /
                (response.data.data.visitorTotal.invite +
                  response.data.data.visitorTotal.walkin)) *
              100;
            // self.VisitorEntryTodayProgressbar =
            //   (response.data.data.visitorEntryToday.invite /
            //     (response.data.data.visitorEntryToday.invite +
            //       response.data.data.visitorEntryToday.walkin)) *
            // 100;
            self.getPeriod(response.data.data.responseTime);
            self.getPeriodDate(response.data.data.responseDate);
            // self.refreshDashboard();
            if (data == 1) {
              self.refreshDashboard();
            }
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            self.LoadingDialog = false;
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    logout() {
      eventBus.$emit("logout");
    },

    getPeriod(data) {
      let that = this;
      that.series[0].data[0] =
        data.find((x) => x.hour == "06") == null
          ? 0
          : data.find((x) => x.hour == "06").entry;
      that.series[0].data[1] =
        data.find((x) => x.hour == "07") == null
          ? 0
          : data.find((x) => x.hour == "07").entry;
      that.series[0].data[2] =
        data.find((x) => x.hour == "08") == null
          ? 0
          : data.find((x) => x.hour == "08").entry;
      that.series[0].data[3] =
        data.find((x) => x.hour == "09") == null
          ? 0
          : data.find((x) => x.hour == "09").entry;
      that.series[0].data[4] =
        data.find((x) => x.hour == "10") == null
          ? 0
          : data.find((x) => x.hour == "10").entry;
      that.series[0].data[5] =
        data.find((x) => x.hour == "11") == null
          ? 0
          : data.find((x) => x.hour == "11").entry;
      that.series[0].data[6] =
        data.find((x) => x.hour == "12") == null
          ? 0
          : data.find((x) => x.hour == "12").entry;
      that.series[0].data[7] =
        data.find((x) => x.hour == "13") == null
          ? 0
          : data.find((x) => x.hour == "13").entry;
      that.series[0].data[8] =
        data.find((x) => x.hour == "14") == null
          ? 0
          : data.find((x) => x.hour == "14").entry;
      that.series[0].data[9] =
        data.find((x) => x.hour == "15") == null
          ? 0
          : data.find((x) => x.hour == "15").entry;
      that.series[0].data[10] =
        data.find((x) => x.hour == "16") == null
          ? 0
          : data.find((x) => x.hour == "16").entry;
      that.series[0].data[11] =
        data.find((x) => x.hour == "17") == null
          ? 0
          : data.find((x) => x.hour == "17").entry;
      that.series[0].data[12] =
        data.find((x) => x.hour == "18") == null
          ? 0
          : data.find((x) => x.hour == "18").entry;
      that.series[0].data[13] =
        data.find((x) => x.hour == "19") == null
          ? 0
          : data.find((x) => x.hour == "19").entry;
      that.series[0].data[14] =
        data.find((x) => x.hour == "20") == null
          ? 0
          : data.find((x) => x.hour == "20").entry;

      that.series[1].data[0] =
        data.find((x) => x.hour == "06") == null
          ? 0
          : data.find((x) => x.hour == "06").exit;
      that.series[1].data[1] =
        data.find((x) => x.hour == "07") == null
          ? 0
          : data.find((x) => x.hour == "07").exit;
      that.series[1].data[2] =
        data.find((x) => x.hour == "08") == null
          ? 0
          : data.find((x) => x.hour == "08").exit;
      that.series[1].data[3] =
        data.find((x) => x.hour == "09") == null
          ? 0
          : data.find((x) => x.hour == "09").exit;
      that.series[1].data[4] =
        data.find((x) => x.hour == "10") == null
          ? 0
          : data.find((x) => x.hour == "10").exit;
      that.series[1].data[5] =
        data.find((x) => x.hour == "11") == null
          ? 0
          : data.find((x) => x.hour == "11").exit;
      that.series[1].data[6] =
        data.find((x) => x.hour == "12") == null
          ? 0
          : data.find((x) => x.hour == "12").exit;
      that.series[1].data[7] =
        data.find((x) => x.hour == "13") == null
          ? 0
          : data.find((x) => x.hour == "13").exit;
      that.series[1].data[8] =
        data.find((x) => x.hour == "14") == null
          ? 0
          : data.find((x) => x.hour == "14").exit;
      that.series[1].data[9] =
        data.find((x) => x.hour == "15") == null
          ? 0
          : data.find((x) => x.hour == "15").exit;
      that.series[1].data[10] =
        data.find((x) => x.hour == "16") == null
          ? 0
          : data.find((x) => x.hour == "16").exit;
      that.series[1].data[11] =
        data.find((x) => x.hour == "17") == null
          ? 0
          : data.find((x) => x.hour == "17").exit;
      that.series[1].data[12] =
        data.find((x) => x.hour == "18") == null
          ? 0
          : data.find((x) => x.hour == "18").exit;
      that.series[1].data[13] =
        data.find((x) => x.hour == "19") == null
          ? 0
          : data.find((x) => x.hour == "19").exit;
      that.series[1].data[14] =
        data.find((x) => x.hour == "20") == null
          ? 0
          : data.find((x) => x.hour == "20").exit;
      that.show = true;
    },

    getPeriodDate(data) {
      let that = this;
      let categories = [];
      data.forEach((value, index) => {
        let strDate = value.transactionDate.split("T")[0].replaceAll("-", "/");
        categories.push(strDate);
        that.series2[0].data[index] = value.entry;
        that.series2[1].data[index] = value.exit;
      });
      that.chartOptions2.xaxis.categories = categories;
    },

    async getMasConfigTerminal() {
      let that = this;
      await axios
        .get(`${that.url}TransactionCarpark/getMasConfigTerminal`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ConfigTerminal = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ConvertTerminalID(value) {
      let self = this;
      if (value != null) {
        return self.ConfigTerminal.filter((x) => x.terminalID == value) == null
          ? ""
          : self.ConfigTerminal.filter((x) => x.terminalID == value)[0] == null
          ? ""
          : self.ConfigTerminal.filter((x) => x.terminalID == value)[0]
              .terminalName;
      }
    },

    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    ChangeFormatDateNotime(date) {
      if (!date) return null;
      var DateData = new Date(date);
      DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear();
    },

    getCurrentDate() {
      let self = this;
      let newDate = new Date();
      let date =
        newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year =
        newDate.getFullYear() < 2500
          ? newDate.getFullYear() + 543
          : newDate.getFullYear();
      let hours =
        newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
      let minutes =
        newDate.getMinutes() < 10
          ? "0" + newDate.getMinutes()
          : newDate.getMinutes();
      let seconds =
        newDate.getSeconds() < 10
          ? "0" + newDate.getSeconds()
          : newDate.getSeconds();
      self.CurrentDatetime = `${date}/${
        month < 10 ? `0${month}` : `${month}`
      }/${year}  ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  /* background-color: #9AC5F4 !important; */
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
