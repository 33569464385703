<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Manage Members Residence
        </div>
        <v-col
          cols="12"
          md="12"
          style="display: flex; justify-content: flex-end"
        >
          <!-- <v-btn
            class="ma-2"
            outlined
            href="ImportMemberTemplate.csv"
            download
          >
            Download
          </v-btn> -->
          <v-btn
            :color="'green'"
            class="ma-2 white--text"
            @click="clearimportdata()"
            :loading="isSelecting"
          >
            Import Excel

            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
          <!-- <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".csv"
            @change="onFileChange"
          /> -->
        </v-col>
        <v-card-text>
          <div>
            <v-card elevation="10" style="padding: 2%; margin-bottom: 2%">
              <v-row>
                <v-col cols="12" md="2" class="pt-5">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" class="pt-5">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar-month"
                        outlined
                        hide-details="auto"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- <v-col cols="12" md="2" class="pt-5">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col> -->
                <v-col cols="12" md="5" lg="5" class="pt-5">
                  <v-text-field
                    dense
                    v-model="search"
                    outlined
                    placeholder="Search"
                    v-on:keyup.enter="SearchMember(search)"
                    placeholder-color="#ACACAC"
                    prepend-inner-icon="mdi-magnify"
                    color="#126496"
                  />
                </v-col>

                <v-col cols="12" md="3" style="text-align: end">
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    style="margin-top: auto; margin-bottom: auto"
                    @click="getDataMemberResidence()"
                  >
                    Search
                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissionsPage.create"
                    color="pink"
                    class="ma-2 white--text"
                    @click="checkDataButton('add', null)"
                  >
                    Create
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" lg="3">
                  <v-autocomplete
                    :items="tenantArray"
                    item-value="tenantID"
                    item-text="tenantName"
                    label="Tenant"
                    v-model="SearchTenant"
                    required
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" lg="2">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="MemberList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">
                      {{ item.firstname + " " + item.lastname }}
                    </td>
                    <td class="header-table-css">
                      <v-icon size="30" color="black">{{
                        item.gender == "M"
                          ? "mdi-face"
                          : item.gender == "F"
                          ? "mdi-face-woman"
                          : ""
                      }}</v-icon>
                    </td>
                    <td class="header-table-css">
                      <v-icon size="30" color="black">{{
                        item.hasCard == 1 ? "mdi-card-bulleted-outline" : ""
                      }}</v-icon>
                    </td>
                    <!-- <td class="header-table-css">{{ item.tenantList }}</td> -->
                    <td class="header-table-css">{{ item.phone }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.createTime) }}
                    </td>
                    <!-- <td style="text-align: center">
                      <img
                        v-if="item.canPreRegister == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td> -->
                    <!-- <td style="text-align: center">
                      <img
                        v-if="item.useFace == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td> -->
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 150px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center; min-width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="checkDataButton('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveMemberByPersonID(item.personID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-2">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="!editCheck" class="text-h5">Create Member</span>
          <span v-if="editCheck" class="text-h5">Edit Member</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="9" md="9">
                <v-row>
                  <v-col cols="12" sm="6" md="2" v-if="editCheck">
                    <v-text-field
                      label="Member Id*"
                      v-model="memberID"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      label="First Name*"
                      v-model="firstname"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      label="Last Name*"
                      v-model="lastname"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      :items="['ไม่ระบุ', 'Male', 'Female']"
                      label="Gender"
                      v-model="gender"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-text-field
                      label="Code"
                      v-model="code"
                      readonly
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Phone Number"
                      hint="*หมายเหตุ ใส่เป็นตัวเลขหรือค่าว่างเท่านั้น"
                      v-model="phone"
                      readonly
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-btn class="ma-2" color="primary" @click="showPhone()">
                      Add
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Id Card รหัสบัตรประชาชน"
                      append-icon="mdi-card-account-details-outline"
                      v-model="idcard"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Passport"
                      append-icon="mdi-passport"
                      v-model="passport"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Email"
                      append-icon="mdi-email-outline"
                      v-model="email"
                      readonly
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-btn class="ma-2" color="primary" @click="showEmail()">
                      Add
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                  <!-- <v-col cols="12" sm="4" md="4">
                    <v-autocomplete
                      :items="tenantArray"
                      item-value="tenantID"
                      item-text="tenantName"
                      label="Tenant"
                      v-model="tenant"
                      @change="getTowerByPersonId(tenant)"
                      multiple
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-btn
                      :disabled="tenant.length == 0 || tenant == ''"
                      class="ma-2"
                      color="primary"
                      @click="showDefault()"
                    >
                      Set
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col> -->
                  <!-- <v-col cols="12" sm="4" md="4">
                    <v-autocomplete
                      :items="MasServiceType"
                      item-value="serviceTypeID"
                      item-text="serviceTypeName"
                      label="Service Type"
                      multiple
                      v-model="serviceType"
                    ></v-autocomplete>
                  </v-col> -->
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field
                      label="Note"
                      append-icon="mdi-note-text-outline"
                      v-model="note"
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col cols="12" sm="3" md="3">
                    <v-switch
                      v-model="preregister"
                      hide-details
                      inset
                      :label="'Preregister'"
                    ></v-switch>
                  </v-col> -->

                  <v-col cols="12" sm="3" md="3">
                    <v-switch
                      v-model="Switch"
                      hide-details
                      inset
                      :label="Switch ? 'Active' : 'InActive'"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3" md="3" style="text-align: center">
                <v-img
                  v-if="urlImg == null"
                  class="bg-white"
                  width="300"
                  style="
                    border: 1px solid #000000;
                    cursor: pointer;
                    background-size: contain;
                  "
                  :aspect-ratio="1"
                  :src="LogoMember"
                  cover
                ></v-img>
                <v-img
                  v-if="urlImg != null"
                  class="bg-white"
                  width="300"
                  style="border: 1px solid #000000; cursor: pointer"
                  :aspect-ratio="1"
                  :src="urlImg"
                  @click="chooseImage = true"
                  cover
                ></v-img>
                <v-file-input
                  id="image"
                  label="Image input"
                  accept="image/png, image/jpeg, image/bmp"
                  append-icon="mdi-camera"
                  v-model="image"
                ></v-file-input>

                <!-- <v-row
                  style="text-align: center; margin-left: 30px"
                  v-if="serviceType == 1 || serviceType == 2"
                >
                  <v-col cols="12" sm="3" md="3">
                    <v-btn
                      class="ma-2"
                      style="min-width: 100px; color: white"
                      color="#22577a"
                      @click="OpenAddCardDialog()"
                    >
                      Card Access
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  style="text-align: center; margin-left: 25px"
                  v-if="serviceType == 1 || serviceType == 3"
                >
                  <v-col cols="12" sm="3" md="3">
                    <v-btn
                      class="ma-2"
                      style="min-width: 100px; color: white"
                      color="#E95C00"
                      @click="OpenAddCardDialogResidence()"
                    >
                      Card Residence
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                :loading="loading4"
                :disabled="loading4"
                color="success"
                @click="addMember()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="clear()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cardDialog" persistent max-width="1000px">
      <v-card>
        <v-card-title style="background-color: #96d5e8; color: black">
          <span class="text-h5">Card Access</span>
        </v-card-title>

        <v-card-text>
          <br />
          <v-row>
            <v-col cols="12" md="6">
              <label>Card Number</label>
              <v-autocomplete
                ref="inputCardNumber"
                v-model="CardNumber"
                :items="CardList"
                :disabled="false"
                item-text="cardNumber"
                color="#126496"
                item-value="cardID"
                prepend-inner-icon="mdi-magnify"
                cols="12"
                sm="6"
                md="3"
                hide-no-data
                return-object
                outlined
                dense
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-row style="height: 50px; margin-top: -0.5rem">
                        <v-col>
                          <v-list-item-title
                            v-html="data.item.cardNumber"
                          ></v-list-item-title>
                        </v-col>
                        <v-col class="text-end">
                          <v-btn
                            text
                            style="
                              background-color: #126496;
                              color: #ffffff;
                              text-transform: none !important;
                              margin-top: -0.4rem;
                            "
                            @click="AddCardDropdownList(data.item)"
                            >Add</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  color="green"
                  class="ma-2 white--text"
                  :elevation="hover ? 10 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="OpenCardDialog('create', null)"
                >
                  Create Access
                  <v-icon right> mdi-plus </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
          <!-- <v-data-table
            :headers="headersCard"
            :items="MemberCardList"
            hide-default-footer
            style="color: #332f2fde"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            single-line
            hide-details
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td class="header-table-css">{{ item.cardNumber }}</td>
                <td class="header-table-css">
                  {{ ChangeFormatDate(item.startDate) }}
                </td>
                <td class="header-table-css">
                  {{ ChangeFormatDate(item.expiredDate) }}
                </td>
                <td class="header-table-css">
                  {{ item.useFace == true ? "Can Use" : "Can't Use" }}
                </td>
                <td style="text-align: center">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="red"
                    @click="DeleteDataDropdown(item)"
                  >
                    <v-icon dark>mdi-trash-can-outline </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table> -->

          <v-data-table
            :headers="headersCard"
            :items="MemberCardList"
            :items-per-page="5"
            class="elevation-1"
            :page="pageCard"
          >
            <template v-slot:item.no="{ item }">
              {{ MemberCardList.indexOf(item) + 1 }}
            </template>
            <template v-slot:item.startDate="{ item }">
              {{ ChangeFormatDateNoTime(item.startDate) }}
            </template>
            <template v-slot:item.expiredDate="{ item }">
              {{ ChangeFormatDateNoTime(item.expiredDate) }}
            </template>
            <template v-slot:item.useFace="{ item }">
              {{ item.useFace ? "Used" : "Not Use" }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text @click="OpenCardDialog('edit', item)">
                <span class="mdi mdi-pencil"></span>
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Edit</span
                >
              </v-btn>
              <v-btn text @click="DeleteDataDropdown(item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="CloseCardDialog('Add')"
              >
                Save
              </v-btn> -->
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CloseCardDialog('Close')"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="CardAccessDialog" persistent max-width="800px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="EditStatus" class="text-h5">Edit Card</span>
          <span v-else class="text-h5">Create Card</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      label="Card Number*"
                      v-model="cardNumber"
                      type="number"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuStartDateEvent"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDateEvent"
                          label="Start Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDateEvent"
                        @change="menuStartDateEvent = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuExpireDateEvent"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="ExpireDateEvent"
                          label="Expire Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="ExpireDateEvent"
                        @change="menuExpireDateEvent = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-switch
                      v-model="useFace"
                      hide-details
                      inset
                      :label="`UseFace`"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-switch
                      v-model="activeCard"
                      hide-details
                      inset
                      :label="activeCard ? 'Active' : 'InActive'"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="12">
                <p
                  class="pt-5"
                  style="color: #444444; font-size: 18px; font-weight: regular"
                >
                  Access
                </p>
                <v-row>
                  <v-autocomplete
                    ref="inputCardNumber2"
                    v-model="SearchData"
                    :items="getCardsAccessGroup"
                    item-text="tagrP_Name"
                    item-value="accessID"
                    color="#126496"
                    prepend-inner-icon="mdi-magnify"
                    cols="12"
                    sm="6"
                    md="3"
                    outlined
                    return-object
                    dense
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-row style="height: 50px; margin-top: -0.5rem">
                            <v-col>
                              <v-list-item-title
                                v-html="data.item.tagrP_Name"
                              ></v-list-item-title>
                            </v-col>
                            <v-col class="text-end">
                              <v-btn
                                text
                                style="
                                  background-color: #126496;
                                  color: #ffffff;
                                  text-transform: none !important;
                                  margin-top: -0.65rem;
                                "
                                @click="AddDataDropdown(data.item)"
                                >Add</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>
                <v-data-table
                  :headers="headersDropdownCard"
                  :items="cardAccessGroupList"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.no="{ item }">
                    {{ cardAccessGroupList.indexOf(item) + 1 }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn text @click="DeleteDataDropdownCard(item)">
                      <img src="@/assets/close.png" width="15" height="15" />
                      <span style="color: #ff4839" class="ml-1 text-capitalize"
                        >Delete</span
                      >
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addCard()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addCard()"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CardAccessDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="CardAccessDialogResidence" persistent max-width="1200px">
      <v-card>
        <CardResidenceComponent />

        <v-card-actions style="background-color: #f9f9f9">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 10px; margin-top: 10px"
            >
              <v-spacer></v-spacer>

              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px; background-color: white"
                append-icon="mdi-close-circle-outline"
                @click="CardAccessDialogResidence = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent max-width="600px">
      <v-card>
        <v-card-title> Email </v-card-title>
        <v-card-text>
          <v-btn
            color="primary"
            dark
            @click="
              dialog3 = !dialog3;
              emailName = '';
            "
          >
            Add Email
          </v-btn>
          <v-data-table
            :headers="headersEmail"
            :items="emailList"
            :items-per-page="10"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.isDefault="{ item, index }">
              <!-- <v-checkbox v-model="item.isDefault"></v-checkbox> -->
              <v-btn
                v-if="item.isDefault"
                color="blue"
                v-model="item.isDefault"
                fab
                small
                dark
              >
                <v-icon>mdi-email-check-outline</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#d9caca"
                v-model="item.isDefault"
                @click="isDefaultemail(index)"
                fab
                small
                dark
              >
                <v-icon>mdi-email-remove-outline</v-icon>
              </v-btn>
            </template>
            <!-- <template v-slot:item.active="{ item }">
              <v-switch v-if="item.isDefault" inset v-model="item.active" disabled></v-switch>
              <v-switch v-else inset v-model="item.active" ></v-switch>
            </template> -->
            <template v-slot:item.action="{ item }">
              <v-btn text @click="DeleteEmail(item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <!-- <v-btn color="success" @click="setDefaultEmail()"> Save </v-btn> -->
          <v-btn color="gray" @click="dialog2 = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPhone" persistent max-width="600px">
      <v-card>
        <v-card-title> Phone </v-card-title>
        <v-card-text>
          <v-btn color="primary" dark @click="addPhoness()"> Add Phone </v-btn>
          <v-data-table
            :headers="headersPhone"
            :items="phoneList"
            :items-per-page="10"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.isDefault="{ item, index }">
              <!-- <v-checkbox v-model="item.isDefault"></v-checkbox> -->
              <v-btn
                v-if="item.isDefault"
                color="blue"
                v-model="item.isDefault"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-arrow-down</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#d9caca"
                v-model="item.isDefault"
                @click="isDefaultphone(index)"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-off</v-icon>
              </v-btn>
            </template>
            <!-- <template v-slot:item.active="{ item }">
              <v-switch v-if="item.isDefault" inset v-model="item.active" disabled></v-switch>
              <v-switch v-else inset v-model="item.active" ></v-switch>
            </template> -->
            <template v-slot:item.action="{ item }">
              <v-btn text @click="DeletePhone(item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <!-- <v-btn color="success" @click="setDefaultPhone()"> Save </v-btn> -->
          <v-btn color="gray" @click="dialogPhone = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDefault" persistent max-width="600px">
      <v-card>
        <v-card-title>Set Default Floor </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersDefault"
            :items="getTowerArray"
            :items-per-page="10"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.isDefaultFloor="{ item, index }">
              <v-btn
                v-if="item.isDefaultFloor"
                color="blue"
                v-model="item.isDefaultFloor"
                @click="isDefaultFloors(index, false)"
                fab
                small
                dark
              >
                <v-icon>mdi-email-check-outline</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#d9caca"
                v-model="item.isDefaultFloor"
                @click="isDefaultFloors(index, true)"
                fab
                small
                dark
              >
                <v-icon>mdi-email-remove-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="dialogDefault = false"> Save </v-btn>
          <v-btn color="gray" @click="dialogDefault = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialogTower" persistent width="800px" scrollable>
      <v-card>
        <v-card-title>Set Default Floor</v-card-title>
        <v-card-title class="white--text primary darken-4">
          Tower Name :

          <v-spacer></v-spacer>

          <v-autocomplete
            :items="towerList"
            clearable
            hide-details
            hide-selected
            v-model="towerName"
            item-text="towerList.towerName"
            item-value="towerList.towerID"
            @change="changeTower(towerName)"
            solo
          >
          </v-autocomplete>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-3">
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="
                text-align: center;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-around;
              "
            >
              <v-card-text
                style="border: outset; width: unset; border-width: thick"
              >
                <table style="min-width: 500px">
                  <thead>
                    <tr>
                      <th class="text-center" style="color: #000000">
                        ชั้น/โซน
                      </th>
                      <th
                        class="text-center"
                        style="padding: 5px; color: #000000"
                        v-for="itemZ in zoneList"
                        :key="itemZ.id"
                      >
                        {{ itemZ.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in floorList" :key="item.id">
                      <td style="color: #000000">{{ item.name }}</td>
                      <td v-for="items in zoneList" :key="items.id">
                        <div>
                          <v-checkbox
                            style="display: grid; justify-content: space-around"
                            v-model="checkbox"
                            :value="CheckBoxData(item.id, items.id)"
                            :disabled="ZoneWithFloor(item.id, items.id)"
                          ></v-checkbox>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th class="text-center" style="color: #000000">
                        ชั้น/โซน
                      </th>
                      <th
                        class="text-center"
                        style="padding: 5px; color: #000000"
                        v-for="itemZ in zoneList"
                        :key="itemZ.id"
                      >
                        {{ itemZ.name }}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn
            color="white"
            style="
              background-color: green;
              color: #ffffff;
              text-transform: none !important;
              margin-top: -0.4rem;
            "
            text
            @click="saveSetFloor()"
          >
            Save
          </v-btn>
          <v-btn
            color="white"
            style="
              background-color: #126496;
              color: #ffffff;
              text-transform: none !important;
              margin-top: -0.4rem;
            "
            text
            @click="dialogTower = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="dialog3" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span>Add Email</span>
          <v-spacer></v-spacer>
          <v-row>
            <v-text-field
              v-model="emailName"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-row>
        </v-card-title>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="addEmail()"> Save</v-btn>
          <v-btn color="gray" @click="dialog3 = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImport" persistent max-width="650px">
      <v-card>
        <v-card-title style="background-color: #96d5e8; color: black">
          <span class="text-h5">Import Member</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-card
                elevation="5"
                class="black--text align-end"
                color="#f5f5f5"
                min-width="200"
                min-height="150"
              >
                <v-card-title>Import File Excel</v-card-title>
                <!-- <v-file-input
                  style="width: 90%"
                  show-size
                  outlined
                  dense
                  label="input file CSV"
                  accept=".csv"
                  v-model="csv"
                ></v-file-input> -->
                <v-file-input
                  style="width: 90%"
                  show-size
                  outlined
                  dense
                  label="input file Excel"
                  accept=".xlsx"
                  v-model="xlsx"
                ></v-file-input>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-card
                elevation="5"
                class="black--text align-end"
                color="#f5f5f5"
                min-width="200"
                min-height="150"
              >
                <v-card-title>Import File Image ZIP</v-card-title>
                <v-file-input
                  style="width: 90%"
                  show-size
                  outlined
                  dense
                  label="input file zip"
                  accept=".zip"
                  v-model="zip"
                ></v-file-input>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <!-- <p style="color: black; padding: 15px">ตัวอย่างไฟล์ Template Excel</p>
            <a style="color: green; padding: 15px; font-size: 18px;" href="../Import/ImportMemberTemplate.xlsx" download>Download</a> -->
            <p style="color: black; padding: 15px">
              ตัวอย่างไฟล์ Template Excel
            </p>
            <a
              style="color: blue; padding: 15px; font-size: 18px"
              href="ImportMemberTest.xlsx"
              download
              >Download</a
            >
          </v-row>
          <v-row>
            <div style="color: #ff0000; padding: 15px">
              * ปรับให้ทุกช่อง Type เป็น Text ก่อนทำการเพิ่มข้อมูล <br />
              * ช่องที่เป็นสีส้ม บังคับกรอก <br />
              * หลังจากทำข้อมูลเสร็จ ให้ save เป็น Excel
              แล้วตรวจทานข้อมูลอีกรอบ<br />
            </div>
          </v-row>
          <v-card-actions style="display: flex; justify-content: flex-end">
            <v-btn color="success" @click="importTable = true">
              Preview Data
            </v-btn>
            <v-btn color="gray" @click="dialogImport = false"> Close </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="importTable" persistent width="2000px">
      <v-card>
        <v-card-title>Preview File Import</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 620px; min-height: 610px">
          <v-data-table
            :headers="headersDropdown"
            :items="listdata"
            :items-per-page="10"
            class="elevation-2"
            :height="530"
          >
            <!-- <template v-slot:item.id="{ index }">
            {{ index + 1 }}
            </template> -->
            <template v-slot:item.base64="{ item }">
              <v-img
                class="bg-white"
                width="100"
                style="border: 1px solid #000000; cursor: pointer"
                :aspect-ratio="1"
                :src="item.base64"
                cover
              ></v-img>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="Import()"> Import </v-btn>
          <v-btn color="error" @click="importTable = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addPhonePopup" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span>Add Phone</span>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <v-autocomplete
                :items="countryCode"
                item-value="code"
                item-text="country"
                label="Country"
                v-model="countryCodeNumber"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="7" md="7">
              <v-text-field
                v-model="PhoneNumber"
                label="Phone"
                hide-details
                single-line
                type="number"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="addPhonelist()"> Save</v-btn>
          <v-btn color="gray" @click="addPhonePopup = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cardDialogResidence" persistent max-width="1000px">
      <v-card>
        <v-card-title style="background-color: #96d5e8; color: black">
          <span class="text-h5">Card Residence</span>
        </v-card-title>

        <v-card-text>
          <br />
          <v-row>
            <v-col cols="12" md="6">
              <label>Card Number</label>
              <v-autocomplete
                ref="inputCardNumber"
                v-model="CardNumberResidence"
                :items="CardListResidence"
                :disabled="false"
                item-text="cardNumber"
                color="#126496"
                item-value="cardID"
                prepend-inner-icon="mdi-magnify"
                cols="12"
                sm="6"
                md="3"
                hide-no-data
                return-object
                outlined
                dense
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-row style="height: 50px; margin-top: -0.5rem">
                        <v-col>
                          <v-list-item-title
                            v-html="data.item.cardNumber"
                          ></v-list-item-title>
                        </v-col>
                        <v-col class="text-end">
                          <v-btn
                            text
                            style="
                              background-color: #126496;
                              color: #ffffff;
                              text-transform: none !important;
                              margin-top: -0.4rem;
                            "
                            @click="AddCardDropdownListResidence(data.item)"
                            >Add</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  color="green"
                  class="ma-2 white--text"
                  :elevation="hover ? 10 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="OpenCardDialogResidence('create')"
                >
                  Create Residence
                  <v-icon right> mdi-plus </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersCardResidence"
            :items="MemberCardListResidence"
            :items-per-page="5"
            class="elevation-1"
            :page="pageCard"
          >
            <template v-slot:item.no="{ item }">
              {{ MemberCardListResidence.indexOf(item) + 1 }}
            </template>
            <template v-slot:item.startDate="{ item }">
              {{ ChangeFormatDateNoTime(item.startDate) }}
            </template>
            <template v-slot:item.expiredDate="{ item }">
              {{ ChangeFormatDateNoTime(item.expiredDate) }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text @click="DeleteDataDropdownResidence(item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="CloseCardDialogResidence('Add')"
              >
                Save
              </v-btn> -->
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CloseCardDialogResidence('Close')"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chooseImage" persistent width="800px">
      <v-card>
        <v-card-title> Image </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersImage"
            :items="imageList"
            :items-per-page="10"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.isDefault="{ item, index }">
              <!-- <v-checkbox v-model="item.isDefault"></v-checkbox> -->
              <v-btn
                v-if="item.isDefault"
                color="blue"
                v-model="item.isDefault"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-arrow-down</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#d9caca"
                v-model="item.isDefault"
                @click="isDefaultimage(index)"
                fab
                small
                dark
              >
                <v-icon>mdi-cellphone-off</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.base64="{ item }">
              <v-img
                class="bg-white"
                width="100"
                style="border: 1px solid #000000; cursor: pointer"
                :aspect-ratio="1"
                :src="item.base64"
                cover
              ></v-img>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text @click="DeleteImage(item)">
                <img src="@/assets/close.png" width="15" height="15" />
                <span style="color: #ff4839" class="ml-1 text-capitalize"
                  >Delete</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <!-- <v-btn color="success" @click="setDefaultImage()"> Save </v-btn> -->
          <v-btn color="gray" @click="chooseImage = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="dialogTower"
      v-model="dialogTower"
      persistent
      max-width="1200px"
      scrollable
    >
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Set Default Floor</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <v-row>
                  <label
                    style="
                      display: flex;
                      align-items: center;
                      font-size: xx-large;
                    "
                    >Tenant Name :</label
                  >
                  <v-spacer></v-spacer>

                  <v-col cols="6" sm="6" md="6">
                    <v-autocomplete
                      v-model="tenantNameList"
                      :items="TenantList"
                      hide-details
                      hide-selected
                      item-text="tenantName"
                      item-value="tenantID"
                      solo
                      @change="MapTenantByTenantID(tenantNameList)"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row> -->
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <template>
                  <v-card class="mx-auto">
                    <v-card-title class="white--text primary darken-4">
                      Tower Name :

                      <v-spacer></v-spacer>

                      <v-autocomplete
                        v-model="towerNameList"
                        :items="TowerListArray"
                        hide-details
                        hide-selected
                        item-text="towerName"
                        item-value="towerID"
                        solo
                        @change="getDataZoneAndFloorByTowerIDOfMember()"
                      >
                      </v-autocomplete>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-card-text
                      style="border: outset; width: unset; border-width: thick"
                    >
                      <table v-if="zoneList.length > 0" style="width: 100%">
                        <thead>
                          <tr>
                            <th class="text-center" style="color: #000000">
                              ชั้น/โซน
                            </th>
                            <th
                              class="text-center"
                              style="padding: 5px; color: #000000"
                              v-for="itemZ in zoneList"
                              :key="itemZ.id"
                            >
                              {{ itemZ.name }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in floorList" :key="item.id">
                            <td style="color: #000000; text-align: center">
                              {{ item.name }}
                            </td>
                            <td v-for="items in zoneList" :key="items.id">
                              <div
                                style="
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <v-checkbox
                                  style="
                                    display: grid;
                                    justify-content: space-around;
                                  "
                                  v-model="checkbox"
                                  @click="
                                    checkboxClick(
                                      CheckBoxData(item.id, items.id)
                                    )
                                  "
                                  :value="CheckBoxData(item.id, items.id)"
                                  :disabled="ZoneWithFloor(item.id, items.id)"
                                ></v-checkbox>
                                <v-btn
                                  v-if="
                                    !ZoneWithFloor(item.id, items.id) &&
                                    !checkButton(
                                      CheckBoxData(item.id, items.id)
                                    )
                                  "
                                  @click="
                                    setDefaultFloor(
                                      CheckBoxData(item.id, items.id),
                                      true
                                    )
                                  "
                                  v-model="checkboxFloor"
                                  x-small
                                  style="cursor: pointer; color: gray"
                                  >Default</v-btn
                                >

                                <v-btn
                                  v-if="
                                    !ZoneWithFloor(item.id, items.id) &&
                                    checkButton(CheckBoxData(item.id, items.id))
                                  "
                                  @click="
                                    setDefaultFloor(
                                      CheckBoxData(item.id, items.id),
                                      false
                                    )
                                  "
                                  v-model="checkboxFloor"
                                  x-small
                                  style="cursor: pointer; color: green"
                                  >Default</v-btn
                                >
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th class="text-center" style="color: #000000">
                              ชั้น/โซน
                            </th>
                            <th
                              class="text-center"
                              style="padding: 5px; color: #000000"
                              v-for="itemZ in zoneList"
                              :key="itemZ.id"
                            >
                              {{ itemZ.name }}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                large
                :loading="loading4"
                :disabled="!valid"
                color="success"
                @click="addTennant()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                large
                append-icon="mdi-close-circle-outline"
                @click="dialogTower = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import CardResidenceComponent from "@/components/CardCarparkComponent";
import JSZip from "jszip";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";
import countryCode from "../../plugins/countryCode.json";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  name: "MemberResidence",
  components: {
    AlertComponent,
    CardResidenceComponent,
  },
  data() {
    return {
      valid: true,
      colors: [
        "primary",
        "secondary",
        "yellow darken-2",
        "red",
        "yellow darken-2",
        "red",
        "yellow darken-2",
        "red",
        "yellow darken-2",
        "red",
      ],
      model: 0,
      countryCode: countryCode,
      chooseImage: false,
      preregister: false,
      dialogPhone: false,
      addPhonePopup: false,
      addPhone: false,
      dialog2: false,
      dialog3: false,
      dialogDefault: false,
      dialogTower: false,
      checkbox: [],
      radioFloor: null,
      loader: null,
      pageCard: 1,
      loading4: false,
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      items: [10, 15, 20, 50, 100],
      itemsDropdown: ["Male", "Female"],
      valueDropdown: [],
      pageCount: 0,
      image: null,
      csv: null,
      xlsx: null,
      zip: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      addData: false,
      Switch: false,
      urlImg: null,
      MemberList: [],
      TowerList: [],
      TowerID: 0,
      ProjectList: [],
      ProjectID: 0,
      tenant: [],
      itemsAction: [{ title: "Edit" }, { title: "Delete" }],
      towerNameList: "",
      tenantNameList: "",
      LogoMember: require("@/assets/picture-default.jpg"),

      locationFloorMap: [],
      floorList: [],
      zoneList: [],
      towerList: [],
      defaultArray: [],
      checkboxFloor: [],
      defaultFloorList: [],
      memberID: "",
      personID: null,
      firstname: "",
      lastname: "",
      gender: "",
      idcard: "",
      passport: "",
      phone: "",
      code: "",
      email: "",
      emailName: "",
      PhoneNumber: "",
      countryCodeNumber: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      emailList: [],
      phoneList: [],
      imageList: [],
      note: "",
      tenantArray: [],
      getTowerArray: [],
      getTowerZone: [],

      tenantAuthFloors: [],
      getRoleAuthFloors: [],
      autoFloors: [],
      selectAuthFloors: [],

      locationList: [],
      TenantList: [],
      TowerListArray: [],

      locationMappingMain: [],
      locationMapping: [],
      towerName: "",
      base64: "",
      isSelecting: false,
      dialogImport: false,
      startDate: "",
      endDate: "",
      menu: false,
      menu1: false,
      modal: false,
      menu2: false,
      editCheck: false,
      activeSearch: null,
      cardDialog: false,
      CardAccessDialog: false,
      CardAccessDialogResidence: false,
      importTable: false,
      MasServiceType: [],
      serviceType: [],
      CardList: [],
      MemberCardList: [],
      CardNumber: "",
      cardDialogResidence: false,
      size: null,
      MemberCardListResidence: [],
      CardListResidence: [],
      zipArrayData: [],
      getCardsAccessGroup: [],
      getCardsAccessGroupList: [],
      active: true,
      CardNumberResidence: "",
      ChooseLocationBTN: false,
      ChooseDefaultFloorBTN: false,

      SearchTenant: "",
      SearchData: null,
      startDateEvent: "",
      ExpireDateEvent: "",
      menuStartDate: false,
      menuEndDate: false,
      menuStartDateEvent: false,
      menuExpireDateEvent: false,
      EditStatus: false,
      useFace: false,
      cardAccessGroupList: [],
      cardAccessGroupListTemp: [],
      InputListDataDropdown: null,
      cardNumber: "",
      activeCard: true,
      permissionsPage: [],
      header:
        "Firstname,Lastname,Gender,IDcard,Passport,Email,CountryCode,Country,PhoneNumber,ServiceType,CardAccessNumber,CardStartDate,CardExpiredDate,TenantID,ProfileImage",
      headersCard: [
        {
          text: "No",
          sortable: false,
          align: "left",
          value: "no",
        },
        {
          text: "CardNumber",
          align: "center",
          sortable: false,
          value: "cardNumber",
        },
        {
          text: "StartDate",
          align: "center",
          sortable: false,
          value: "startDate",
        },
        {
          text: "ExpireDate",
          align: "center",
          sortable: false,
          value: "expiredDate",
        },
        {
          text: "UseFace",
          align: "center",
          sortable: false,
          value: "useFace",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersCardResidence: [
        {
          text: "No",
          sortable: false,
          align: "left",
          value: "no",
        },
        {
          text: "CardNumber",
          align: "center",
          sortable: false,
          value: "cardNumber",
        },
        {
          text: "StartDate",
          align: "center",
          sortable: false,
          value: "startDate",
        },
        {
          text: "expiredDate",
          align: "center",
          sortable: false,
          value: "expiredDate",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headersEmail: [
        {
          text: "Default Email",
          align: "center",
          value: "isDefault",
        },
        {
          text: "Email Name",
          align: "left",
          value: "email",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
      headersPhone: [
        {
          text: "Default Phone",
          align: "center",
          value: "isDefault",
        },
        {
          text: "Country Name",
          align: "left",
          value: "country",
          sortable: false,
        },
        {
          text: "Country Code",
          align: "left",
          value: "code",
          sortable: false,
        },
        {
          text: "Phone Number",
          align: "left",
          value: "phoneNumber",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],

      headersImage: [
        {
          text: "Default Image",
          align: "center",
          value: "isDefault",
        },
        {
          text: "Name Image",
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: "Image",
          align: "center",
          value: "base64",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],

      headersDefault: [
        {
          text: "Default Floor",
          align: "center",
          value: "isDefaultFloor",
        },
        {
          text: "Location ID",
          align: "left",
          value: "locationID",
          sortable: false,
        },
        {
          text: "Floor Name",
          align: "left",
          value: "floorName",
          sortable: false,
        },
      ],
      importAllData: [],
      listdata: [],
      headersDropdown: [
        {
          text: "id",
          align: "center",
          value: "id",
        },
        {
          text: "First Name",
          align: "left",
          value: "Firstname",
          sortable: false,
        },
        {
          text: "Last Name",
          align: "center",
          value: "Lastname",
          sortable: false,
        },
        {
          text: "Gender",
          align: "left",
          value: "Gender",
          sortable: false,
        },
        {
          text: "IDcard",
          align: "left",
          value: "IDcard",
          sortable: false,
        },
        {
          text: "Passport",
          align: "left",
          value: "Passport",
          sortable: false,
        },
        {
          text: "Email",
          align: "left",
          value: "Email",
          sortable: false,
        },
        // {
        //   text: "Country Code",
        //   align: "left",
        //   value: "CountryCode",
        //   sortable: false,
        // },
        {
          text: "Country",
          align: "left",
          value: "Country",
          sortable: false,
        },
        {
          text: "Phone Number",
          align: "left",
          value: "PhoneNumber",
          sortable: false,
        },
        {
          text: "Service Type",
          align: "left",
          value: "ServiceType",
          sortable: false,
        },
        {
          text: "CardResidence Number",
          align: "left",
          value: "CardResidenceNumber",
          sortable: false,
        },
        {
          text: "Card Residence Start Date",
          align: "left",
          value: "CardResidenceStartDate",
          sortable: false,
        },
        {
          text: "Card Residence Expired Date",
          align: "left",
          value: "CardResidenceExpiredDate",
          sortable: false,
        },
        {
          text: "Tenant ID",
          align: "left",
          value: "TenantID",
          sortable: false,
        },
        {
          text: "LicensePlate",
          align: "left",
          value: "LicensePlate",
          sortable: false,
        },
        {
          text: "Profile Image",
          align: "left",
          value: "ProfileImage",
          sortable: false,
        },
        {
          text: "Image",
          align: "center",
          value: "base64",
          sortable: false,
        },
      ],
      headersDropdownCard: [
        {
          text: "No",
          align: "center",
          value: "no",
        },
        {
          text: "Project Name",
          align: "left",
          value: "projectName",
          sortable: false,
        },
        {
          text: "Tower Name",
          align: "left",
          value: "towerName",
          sortable: false,
        },
        {
          text: "Tag GroupName",
          align: "center",
          value: "tagGroupName",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
        },
        {
          text: "Gender",
          align: "center",
          sortable: false,
        },
        {
          text: "CardResidence",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Tenant",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "Phone",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Email",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "CreateDate",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "PreRegister",
        //   align: "center",
        //   value: "canPreRegister",
        //   sortable: false,
        // },
        // {
        //   text: "UseFace",
        //   align: "center",
        //   value: "useFace",
        //   sortable: false,
        // },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    // checkbox() {
    //   let self = this;
    //   if (self.defaultArray.find((a) => a.towerID == self.towerName) != null) {
    //     self.defaultArray.find((a) => a.towerID == self.towerName).selectFloor =
    //       self.checkbox;
    //   }
    // },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
    CardNumber() {
      this.$refs.inputCardNumber.reset();
    },
    image(newVal) {
      if (newVal) {
        this.createBase64Image();
      } else {
        this.base64 = "";
        this.urlImg = null;
      }
    },
    csv(newVal) {
      if (newVal) {
        this.createInput(newVal);
      } else {
        this.csv = null;
      }
    },
    xlsx(newVal) {
      if (newVal) {
        this.createInputexcel(newVal);
      } else {
        this.xlsx = null;
      }
    },
    zip(newVal) {
      if (newVal) {
        this.createInputzip(newVal);
      } else {
        this.zip = null;
      }
    },
    SearchData() {
      this.$refs.inputCardNumber2.reset();
    },
  },
  mounted() {
    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    // this.getTennant();
    this.getDataMemberResidence();
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
      // this.isSelecting = false;
    },
    AddCardDropdownListResidence(val) {
      if (val != null) {
        var CheckDuplicate = this.MemberCardListResidence.find(
          (x) => x.cardID == val.cardID
        );
        if (CheckDuplicate != null) {
          Swal.fire("Duplicate Data !");
        } else {
          var temp = {
            cardID: val.cardID,
            cardNumber: val.cardNumber,
            startDate: val.startDate,
            expiredDate: val.expiredDate,
            useFace: val.useFace,
            active: val.active,
          };
          this.MemberCardListResidence.push(temp);
        }
      }
    },
    setDefaultEmail() {
      this.emailList.forEach((element) => {
        if (element.isDefault == true) {
          this.email = element.email;
        }
      });
      this.dialog2 = false;
    },
    addPhoness() {
      this.countryCodeNumber = "";
      this.PhoneNumber = "";
      this.addPhonePopup = true;
    },
    setDefaultPhone() {
      this.phoneList.forEach((element) => {
        if (element.isDefault == true) {
          this.code = element.code;
          this.phone = element.phoneNumber;
        }
      });
      this.dialogPhone = false;
    },
    setDefaultImage() {
      this.imageList.forEach((element) => {
        if (element.isDefault == true) {
          this.urlImg = element.base64;
          this.image = element.name;
        }
      });
      this.chooseImage = false;
    },
    isDefaultemail(index) {
      this.emailList.forEach((element) => {
        element.isDefault = false;
      });

      this.emailList[index].isDefault = true;
      this.emailList[index].active = true;
      this.email = this.emailList[index].email;
    },
    isDefaultphone(index) {
      this.phoneList.forEach((element) => {
        element.isDefault = false;
      });

      this.phoneList[index].isDefault = true;
      this.phoneList[index].active = true;
      this.code = this.phoneList[index].code;
      this.phone = this.phoneList[index].phoneNumber;
    },
    isDefaultimage(index) {
      this.imageList.forEach((element) => {
        element.isDefault = false;
      });

      this.imageList[index].isDefault = true;
      this.urlImg = this.imageList[index].base64;
      this.image = this.imageList[index].name;
    },
    isDefaultFloors(index, bool) {
      // this.getTowerArray.forEach((element) => {
      //   element.isDefaultFloor = false;
      // });

      this.getTowerArray[index].isDefaultFloor = bool;
    },
    saveSetFloor() {
      // console.log(this.checkbox);
      this.dialogTower = false;
    },
    addEmail() {
      // console.log(this.emailRules);
      let temp = {
        email: this.emailName,
        isDefault: this.emailList.length == 0 ? true : false,
        active: true,
      };
      if (temp.isDefault == true) {
        this.email = temp.email;
      }
      this.emailList.push(temp);
      this.emailName = "";
      this.dialog3 = false;
    },
    addPhonelist() {
      let temp = {
        code: this.countryCodeNumber,
        country:
          this.countryCode.find((a) => a.code == this.countryCodeNumber) == null
            ? ""
            : this.countryCode.find((a) => a.code == this.countryCodeNumber)
                .country,
        phoneNumber: this.PhoneNumber,
        isDefault: this.phoneList?.length == 0 ? true : false,
        active: true,
      };
      if (temp.isDefault == true) {
        this.code = temp.code;
        this.phone = temp.phoneNumber;
      }
      this.phoneList.push(temp);
      this.PhoneNumber = "";
      this.countryCodeNumber = "";
      this.addPhonePopup = false;
    },
    showEmail() {
      this.dialog2 = true;
    },
    showPhone() {
      this.dialogPhone = true;
    },
    showDefault() {
      // this.dialogDefault = true;
      let that = this;
      that.TenantList = [];
      // that.defaultFloorList = [];

      that.tenantNameList = that.tenant[0];
      // let dataId = self.tenant[0]
      that.tenant.forEach((data) => {
        let dataTemp = that.tenantArray.find((a) => a.tenantID == data);

        let temp = {
          tenantID: dataTemp.tenantID,
          tenantName: dataTemp.tenantName,
        };
        that.TenantList.push(temp);

        // this.setTenantByTenantID(data);
      });
      // console.log(that.TenantList);

      // this.getZoneAndTowerList();
      this.MapTenantByTenantID(this.tenant[0]);
      // this.getTenantByTenantID(this.tenant[0]);
      this.dialogTower = true;
    },

    getTowerByPersonId(data) {
      let self = this;

      if (data.length == 0) {
        self.defaultFloorList = [];
        return;
      }
      let temp = {
        personId: self.personID == "" ? null : self.personID,
        tenantArray: data,
      };
      axios
        .post(`${self.url}Member/getTowerByPersonId`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.defaultFloorList = response.data.data;
            if (self.defaultFloorList.length > 0) {
              // console.log(response.data.data);
              self.towerNameList = self.defaultFloorList[0].towerID;
            }
            // console.log(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    clearstartDate() {
      this.startDate = null;
    },
    clearendDate() {
      this.endDate = null;
    },

    Preview_image() {
      if (this.image == null) {
        this.urlImg = null;
        this.image = null;
      } else {
        this.urlImg = URL.createObjectURL(this.image);
      }
    },
    checkDataButton(type, data) {
      let that = this;
      if (type == "add") {
        that.LoadingDialog = true;
        that.imageList = [];
        that.urlImg = null;
        that.image = null;
        that.editCheck = false;

        that.Switch = true;
        that.firstname = "";
        that.lastname = "";
        that.gender = "";
        that.idcard = "";
        that.passport = "";
        that.phone = "";
        that.email = "";
        that.note = "";
        that.code = "";
        that.personID = "";
        that.serviceType = [];
        that.tenant = [];
        that.phoneList = [];
        that.emailList = [];
        that.defaultArray = [];
        that.CardList = [];
        that.MemberCardListResidence = [];
        that.dialog = true;
        that.LoadingDialog = false;
      } else {
        // that.editCheck = true;
        // that.dialog = true;
        // that.firstname = data.firstname;
        // that.lastname = data.lastname;
        // that.gender = data.gender == "M" ? "Male" : "Female";
        // that.idcard = data.idcard;
        // that.passport = data.passport;
        // that.phone = data.phone;
        // that.email = data.email;
        // that.note = data.note;
        // that.personID = data.personID;
        // that.tenant = [];
        // for (let i = 0; i < data.tenantList.length; i++) {
        //   that.tenant.push(data.tenantList[i].tenantID);
        // }
        that.getMemberByPersonID(data.personID);
      }
    },
    clear() {
      let that = this;
      that.imageList = [];
      that.urlImg = null;
      that.image = null;
      that.editCheck = false;

      that.Switch = true;
      that.firstname = "";
      that.lastname = "";
      that.gender = "";
      that.idcard = "";
      that.passport = "";
      that.phone = "";
      that.email = "";
      that.note = "";
      that.code = "";
      that.personID = "";
      that.serviceType = [];
      that.tenant = [];
      that.phoneList = [];
      that.emailList = [];
      that.defaultArray = [];
      that.dialog = false;
    },
    mapEdit(data) {
      let that = this;
      that.urlImg = null;
      that.image = null;
      that.defaultArray = [];
      that.editCheck = true;

      that.firstname = data.firstname;
      that.lastname = data.lastname;
      that.gender =
        data.gender == "M" ? "Male" : data.gender == "F" ? "Female" : "";
      that.idcard = data.idcard;
      that.passport = data.passport;
      that.phone = data.phone;
      // that.email = data.email;
      that.note = data.note;
      that.personID = data.personID;
      that.memberID = data.id;
      that.MemberCardList = data.cardList;
      that.emailList = data.emailList;
      that.MemberCardListResidence = data.cardResidenceList;
      that.serviceType = data.serviceType;
      that.defaultFloorList = data.defaultFloorList;
      if (that.defaultFloorList.length > 0) {
        that.towerNameList = data.defaultFloorList[0].towerID;
      }
      that.imageList = data.imageList == null ? [] : data.imageList;
      that.phoneList = data.phoneList == null ? [] : data.phoneList;

      // that.defaultArray = data.defaultList;
      that.preregister = data.preregister;
      that.Switch = data.active == true ? data.active : false;
      that.tenant = [];

      for (let i = 0; i < data.tenantList.length; i++) {
        that.tenant.push(data.tenantList[i].tenantID);
      }
      // console.log(that.tenant);
      that.emailList?.forEach((element) => {
        if (element.isDefault == true) {
          that.email = element.email;
        }
      });
      that.imageList?.forEach((element) => {
        if (element.isDefault == true) {
          that.image = element.name;
          that.urlImg = element.base64;
        }
      });
      that.phoneList?.forEach((element) => {
        if (element.isDefault == true) {
          that.code = element.code;
          that.phone = element.phoneNumber;
        }
      });

      that.LoadingDialog = false;
      that.dialog = true;
    },
    checkVakidates() {
      let that = this;
      if (
        that.firstname == "" ||
        that.lastname == ""

        //|| that.gender == "" ||
        // that.phone == "" ||
        // that.idcard == "" ||
        // that.email == ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    setDefaultFloor(value, checked) {
      let that = this;
      if (checked) {
        this.OneFloorinZone(value);
        if (that.checkbox.indexOf(value) != -1) {
          that.checkboxFloor.push(value);
        }
      } else {
        let index = that.checkboxFloor.indexOf(value);
        that.checkboxFloor.splice(index, 1);
      }
    },

    OneFloorinZone(value) {
      let that = this;
      let mainZoneID = this.locationMapping.find(
        (x) => x.locationID == value && x.towerID == this.towerName
      ).zoneID;

      let defaultData = this.defaultArray.find(
        (f) => f.towerID == this.towerName
      ).defaultFloor;

      defaultData.forEach((item) => {
        let findZoneDuplicate = this.locationMapping.find(
          (x) => x.locationID == item && x.towerID == this.towerName
        ).zoneID;
        if (mainZoneID == findZoneDuplicate) {
          if (that.checkbox.find((a) => a == value) != null) {
            let index = that.checkboxFloor.indexOf(item);
            that.checkboxFloor.splice(index, 1);
          }
        }
      });

      // that.defaultFloorList.find((x) => x.tenantID == that.tenantID).dataList = that.defaultArray;
      that.defaultFloorList = that.defaultArray;
    },

    checkboxClick(value) {
      let that = this;

      if (that.defaultArray.find((a) => a.towerID == that.towerName) != null) {
        that.defaultArray.find((a) => a.towerID == that.towerName).selectFloor =
          that.checkbox;
      }

      that.checkboxFloor.forEach((x) => {
        if (that.checkbox.find((a) => a == x) == null) {
          let index = that.checkboxFloor.indexOf(value);
          that.checkboxFloor.splice(index, 1);
        }
      });

      // that.defaultFloorList.find((x) => x.tenantID == that.tenantID).dataList = that.defaultArray;
      that.defaultFloorList = that.defaultArray;
    },

    CheckDefaultFloor() {
      let data = [];
      data = this.defaultArray.filter((x) => x.defaultFloor.length > 0);
      if (data.length == 0) {
        Swal.fire({
          icon: "warning",
          title: "Please Set Default Floor",
          showConfirmButton: false,
          timer: 1500,
        });
        return true;
      } else {
        return false;
      }
    },

    getDataMemberResidence() {
      let self = this;
      self.LoadingDialog = true;
      // self.page = 1;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Residence/getDataResidenceMember`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MemberList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchMember(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
        tenantID: self.SearchTenant == "" ? null : self.SearchTenant,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Residence/getDataResidenceMember`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MemberList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getTennant() {
      let self = this;
      axios
        .get(`${self.url}Tenant/getDataTenant`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.tenantArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getMasServiceType() {
      let self = this;
      axios
        .get(`${self.url}Residence/getMasServiceType`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MasServiceType = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getTowerByTenantID() {
      let self = this;
      let temp = {
        tenantArray: self.tenant,
      };
      axios
        .post(`${self.url}Member/getTowerByTenantID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.getTowerArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getZoneAndTowerList() {
      let self = this;
      let temp = {
        tenantArray: self.tenant,
      };
      axios
        .post(`${self.url}Member/getZoneAndTowerList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.getTowerZone = response.data.data;
            self.locationMappingMain =
              self.getTowerZone.accessMapping.locationMapping;
            self.towerList = self.getTowerZone.towerList;
            self.towerName = self.getTowerZone.towerList[0].towerList.towerID;
            self.locationMapping = self.locationMappingMain.filter(
              (x) => x.towerID == self.towerName
            );
            self.floorList = self.getTowerZone.towerList[0].towerList.floorList;
            self.zoneList = self.getTowerZone.towerList[0].towerList.zoneList;
            self.checkbox = [];
            self.towerList.forEach((element) => {
              let tempData = {
                towerID: element.towerList.towerID,
                defaultID: [],
              };
              self.defaultArray.push(tempData);
            });

            self.checkbox = self.defaultArray.find(
              (a) => a.towerID == self.towerName
            ).defaultID;
            self.$forceUpdate();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    changeTower(tower) {
      let self = this;

      self.checkbox = [];
      self.locationMapping = [];
      self.floorList = [];
      self.zoneList = [];

      self.checkbox = self.defaultArray.find(
        (a) => a.towerID == tower
      ).defaultID;
      self.locationMapping = self.locationMappingMain.filter(
        (x) => x.towerID == tower
      );
      self.floorList = self.towerList.find(
        (x) => x.towerList.towerID == tower
      ).towerList.floorList;
      self.zoneList = self.towerList.find(
        (x) => x.towerList.towerID == tower
      ).towerList.zoneList;

      self.$forceUpdate();
    },
    getTowerByRoleID() {
      let self = this;
      axios
        .get(`${self.url}Member/getTowerByRoleID`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TowerList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getProjectIDByRoleID() {
      let self = this;
      axios
        .get(`${self.url}Member/getProjectIDByRoleID`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProjectList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getMemberByPersonID(id) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        personId: id,
      };
      axios
        .post(`${self.url}Member/getMemberByPersonID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.mapEdit(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    inActiveMemberByPersonID(id) {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        personId: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Member ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${self.url}Member/inActiveMemberByPersonID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                self.getDataMemberResidence();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              self.LoadingDialog = false;
            })
            .catch(function (error) {
              self.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          self.LoadingDialog = false;
        }
        self.LoadingDialog = false;
      });
    },

    addMember() {
      let self = this;
      // if (self.checkVakidates()) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "กรุณากรอกข้อมูลให้ครบถ้วน",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;
      // }

      // if (self.CheckDefaultFloor()) {
      //   return;
      // }

      // let cardNumber = [];
      // let cardResidenceNumber = [];

      // for (var i = 0; i < self.MemberCardList.length; i++) {
      //   let data = {
      //     cardNumber: self.MemberCardList[i].cardNumber,
      //   };
      //   cardNumber.push(data);
      // }
      // for (var j = 0; j < self.MemberCardListResidence.length; j++) {
      //   let data = {
      //     cardNumber: self.MemberCardListResidence[j].cardNumber,
      //   };
      //   cardResidenceNumber.push(data);
      // }

      self.LoadingDialog = true;

      if (!self.editCheck) {
        let temp = {
          firstname: self.firstname,
          lastname: self.lastname,
          gender:
            self.gender == "Male" ? "M" : self.gender == "Female" ? "F" : "",
          idcard: self.idcard,
          passport: self.passport,
          phone: self.phone,
          email: self.email,
          note: self.note,
          // tenantArray: self.tenant,
          emailList: self.emailList,
          phoneList: self.phoneList,
          imageList: self.imageList,
          // cardList: cardNumber,
          // cardResidenceList: cardResidenceNumber,
          // towerArray: self.getTowerArray,
          // defaultFloorList: self.defaultFloorList,
          residentID: 1,
          serviceType: [4],
          active: self.Switch,
          // preregister: self.preregister,
        };
        axios
          .post(`${self.url}Residence/addResidenceMember`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              self.dialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            self.page = 1;
            self.getDataMemberResidence();
          })
          .catch(function (error) {
            // self.LoadingDialog = false;
            self.getDataMemberResidence();
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          personID: self.personID,
          firstname: self.firstname,
          lastname: self.lastname,
          gender:
            self.gender == "Male" ? "M" : self.gender == "Female" ? "F" : "",
          idcard: self.idcard,
          passport: self.passport,
          phone: self.phone,
          email: self.email,
          note: self.note,
          // tenantArray: self.tenant,
          emailList: self.emailList,
          phoneList: self.phoneList,
          imageList: self.imageList,
          // cardList: cardNumber,
          // cardResidenceList: cardResidenceNumber,
          // towerArray: self.getTowerArray,
          // defaultFloorList: self.defaultFloorList,
          residentID: 1,
          serviceType: [4],
          active: self.Switch,
          // preregister: self.preregister,
        };
        axios
          .post(`${self.url}Residence/updateResidenceMember`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              self.dialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            self.getDataMemberResidence();
          })
          .catch(function (error) {
            // self.LoadingDialog = false;
            self.getDataMemberResidence();
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }

      // setTimeout(function () {
      //   self.LoadingDialog = false;
      // }, 2000);
    },

    onFileChangeCSV(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createInput(files[0]);
    },
    createInput(file) {
      var that = this;
      that.listdata = [];
      that.LoadingDialog = true;
      let promise = new Promise((resolve) => {
        var reader = new FileReader();

        reader.onload = () => {
          // console.log(e);

          resolve((that.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          that.importAllData = result.split("\r\n");
          if (that.importAllData.length > 0) {
            for (let i = 1; i < that.importAllData.length; i++) {
              if (that.header != that.importAllData[0]) {
                Swal.fire({
                  icon: "warning",
                  title: "Format Incorrect",
                  showConfirmButton: false,
                });
                that.csv = null;
                return;
              } else {
                let tempData = that.importAllData[i].split(",");

                let fileList = {
                  Firstname: tempData[0],
                  Lastname: tempData[1],
                  Gender: tempData[2],
                  IDcard: tempData[3],
                  Passport: tempData[4],
                  Email: tempData[5],
                  CountryCode: tempData[6],
                  Country: tempData[7],
                  PhoneNumber: tempData[8],
                  ServiceType: tempData[9],
                  CardAccessNumber: tempData[10],
                  CardStartDate: tempData[11],
                  CardExpiredDate: tempData[12],
                  // CardResidenceNumber: tempData[13],
                  // CardResidenceStartDate: tempData[14],
                  // CardResidenceExpiredDate: tempData[15],
                  TenantID: tempData[13],
                  ProfileImage: tempData[14],
                  base64: "",
                  id: i,
                };
                that.listdata.push(fileList);
              }
            }
            // console.log(that.listdata);
            if (that.zipArrayData.length > 0) {
              that.mapImage();
            } else {
              that.LoadingDialog = false;
            }
            // that.Import();
            // that.importTable = true;
          } else {
            return;
          }
        },
        (error) => {
          /* handle an error */
          console.log(error);
        }
      );
    },

    createInputexcel(file) {
      var that = this;
      // console.log("readerfile excel");
      that.listdata = [];
      that.LoadingDialog = true;
      var reader = new FileReader();

      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
        });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          // var json_object = JSON.stringify(XL_row_object);
          // console.log(XL_row_object);
          that.listdata = XL_row_object;

          if (that.zipArrayData.length > 0) {
            that.mapImage();
          } else {
            that.LoadingDialog = false;
          }
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    },

    async createInputzip(files) {
      var that = this;
      // that.LoadingDialog = true;
      // let data = '';
      // console.log(files);
      var zip = new JSZip();
      zip.loadAsync(files).then(function (zip) {
        // console.log(zip.files);
        var filePromises = [];
        Object.keys(zip.files).forEach(function (filename) {
          let file = zip.files[filename];
          let fileName = filename.split("/")[filename.split("/").length - 1];
          if (!file.dir) {
            filePromises.push(
              file.async("blob").then(function (blob) {
                var reader = new FileReader();
                reader.onload = function () {
                  var dataUrl = reader.result;
                  var base64 =
                    "data:image/jpeg;base64," + dataUrl.split(",")[1];
                  let temp = {
                    filename: fileName,
                    base64: base64,
                  };
                  that.zipArrayData.push(temp);
                  that.mapBase64Image(fileName, base64);
                };
                reader.readAsDataURL(blob);
              })
            );
          }
        });
      });
    },

    mapImage() {
      let that = this;

      that.listdata.forEach((data) => {
        let findBase64 = "";
        if (that.zipArrayData.length > 0) {
          findBase64 = that.zipArrayData.find(
            (x) => x.filename == data.ProfileImage
          ).base64;
          data.base64 = findBase64;
          //  console.log(data);
        }
      });
      that.LoadingDialog = false;
    },
    mapBase64Image(fileName, base64) {
      let that = this;

      if (that.listdata.length > 0) {
        that.listdata.find((x) => x.ProfileImage == fileName).base64 = base64;
      }
    },

    Import() {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        listdata: that.listdata,
      };
      axios
        .post(`${that.url}Member/ImportDataMemberResidence`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            that.importTable = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (response.data.status == 1) {
            that.LoadingDialog = false;
            that.importTable = false;
            Swal.fire({
              title: response.data.message,
              text: "บรรทัดที่ : " + response.data.data.id,
              icon: "warning",
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImg = URL.createObjectURL(file);
    },
    getImage() {
      if (!this.image) return;
      return URL.createObjectURL(this.image);
    },
    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        tenantID: self.SearchTenant == "" ? null : self.SearchTenant,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Residence/getDataResidenceMember`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MemberList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        tenantID: self.SearchTenant == "" ? null : self.SearchTenant,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Residence/getDataResidenceMember`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.MemberList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    OpenAddCardDialogResidence() {
      let self = this;
      self.cardDialogResidence = true;
      this.getCardListResidence();
    },

    createBase64Image() {
      let self = this;
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.name = this.image.name; //get the image's name
      reader.size = this.image.size;
      reader.onload = (event) => {
        // this.base64 = event.target.result;
        // this.urlImg = this.base64;
        // this.size = event.target.size;
        // console.log(this.base64);

        var img = new Image();
        img.src = event.target.result;
        img.name = event.target.name;
        img.size = event.target.size;
        img.onload = function (el) {
          var elem = document.createElement("canvas");
          var scaleFactor = 400 / el.target.width;
          elem.width = 400;
          elem.height = el.target.height * scaleFactor;

          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          var srcEncoded = ctx.canvas.toDataURL("image/png", 1);
          self.base64 = srcEncoded;
          self.urlImg = self.base64;
          // console.log(srcEncoded);
          self.UploadFiles();
        };
      };
    },

    createBase64Zip() {
      let self = this;
      const reader = new FileReader();
      reader.readAsDataURL(this.zip);
      reader.name = this.zip.name; //get the image's name
      reader.size = this.zip.size;
      reader.onload = (event) => {
        // this.base64 = event.target.result;
        // this.urlImg = this.base64;
        // this.size = event.target.size;
        // console.log(this.base64);

        var img = new Image();
        img.src = event.target.result;
        img.name = event.target.name;
        img.size = event.target.size;
        img.onload = function (el) {
          var elem = document.createElement("canvas");
          var scaleFactor = 400 / el.target.width;
          elem.width = 400;
          elem.height = el.target.height * scaleFactor;

          var ctx = elem.getContext("2d");
          ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

          var srcEncoded = ctx.canvas.toDataURL("image/png", 1);
          self.base64 = srcEncoded;
          self.urlImg = self.base64;
          // console.log(srcEncoded);
          self.UploadFiles();
        };
      };
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    UploadFiles() {
      let self = this;

      let temp = {
        filesName: self.image.name,
        base64: self.base64,
      };
      // console.log(temp);
      axios
        .post(`${self.url}Residence/checkFrontalFaceImage`, temp)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.status == 0) {
            if (self.imageList.length > 0) {
              self.imageList.forEach((element) => {
                element.isDefault = false;
              });
            }

            let dataImage = {
              name: response.data.data.imangeName,
              base64: response.data.data.imageResponse,
              isDefault: true,
            };

            self.urlImg = dataImage.base64;

            self.imageList.push(dataImage);

            // self.Preview_image();
          }
          if (response.data.status == 1) {
            Swal.fire({
              icon: "warning",
              title: "รูปนี้ไม่เหมาะสมในการนำมาลงทะเบียน",
              showConfirmButton: true,
            });
            if (self.urlImg != null) {
              if (self.imageList.length > 0) {
                self.urlImg = self.imageList.find(
                  (a) => a.isDefault == true
                ).base64;
              } else {
                self.urlImg = "";
                self.image = "";
              }
            }

            // self.csv = null;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    ChangeFormatDateNoTime(date) {
      if (!date) return null;

      var DateData = new Date(date);
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },

    OpenAddCardDialog() {
      let self = this;
      self.cardDialog = true;
      this.getCardList();
    },

    AddCardList() {
      let self = this;
      var temp = {
        cardNumber: self.CardNumber,
        status: "Wait",
      };
      self.CardList.push(temp);
      self.CardNumber = "";
    },

    AddCardListResidence() {
      let self = this;
      var temp = {
        cardNumber: self.CardNumberResidence,
        status: "Wait",
      };
      self.CardListResidence.push(temp);
      self.CardNumberResidence = "";
    },

    // EditCardList(data) {
    //   console.log(data);
    // },

    // DeleteDataInCardList(id) {
    //   console.log(id);
    // },

    checkButton(value) {
      let that = this;
      let defaultFloor = that.checkboxFloor.find((x) => x == value);
      let selectFloor = that.checkbox.find((x) => x == value);
      if (defaultFloor != null && selectFloor != null) {
        return true;
      }

      return false;
    },

    CheckBoxData(floorID, zoneID) {
      let temp = "";
      let dataCheck = this.locationMapping.find(
        (x) => x.floorID == floorID && x.zoneID == zoneID
      );
      if (dataCheck != null) {
        temp = dataCheck.locationID;
        return temp;
      }
      return temp;
    },

    ZoneWithFloor(floorID, zoneID) {
      let dataCheck = this.locationMapping.find(
        (x) => x.floorID == floorID && x.zoneID == zoneID
      );
      if (dataCheck != null) {
        // console.log("false");
        return false;
      }

      return true;
    },

    getCardList() {
      let self = this;
      axios
        .get(`${self.url}Member/getCardList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getCardListResidence() {
      let self = this;
      axios
        .get(`${self.url}Member/getCardResidenceList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardListResidence = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    addTennant() {
      let that = this;
      that.LoadingDialog = true;
      that.dialogTower = false;

      // console.log(that.defaultFloorList);

      that.LoadingDialog = false;
    },

    AddCardDropdownList(val) {
      if (val != null) {
        var CheckDuplicate = this.MemberCardList.find(
          (x) => x.cardID == val.cardID
        );
        if (CheckDuplicate != null) {
          Swal.fire("Duplicate Data !");
        } else {
          var temp = {
            cardID: val.cardID,
            cardNumber: val.cardNumber,
            startDate: val.startDate,
            expiredDate: val.expiredDate,
            useFace: val.useFace,
            active: val.active,
          };
          this.MemberCardList.push(temp);
        }
      }
    },

    DeleteEmail(item) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.emailList.indexOf(item);
          self.emailList.splice(Index, 1);
          if (self.emailList.length == 0) {
            this.email = "";
          }
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    DeletePhone(item) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.phoneList.indexOf(item);
          self.phoneList.splice(Index, 1);
          if (self.phoneList.length == 0) {
            this.code = null;
            this.phone = null;
          }
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    DeleteImage(item) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.imageList.indexOf(item);
          self.imageList.splice(Index, 1);
          if (self.imageList.length == 0) {
            self.image = null;
            self.urlImg = "";
          }
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    DeleteDefaultFloor(item) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.getTowerArray.indexOf(item);
          self.getTowerArray.splice(Index, 1);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },

    DeleteDataDropdown(item) {
      let self = this;
      // var del = Swal.fire("Are you sure you want to delete !");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.MemberCardList.indexOf(item);
          self.MemberCardList.splice(Index, 1);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },

    DeleteDataDropdownResidence(item) {
      let self = this;
      // var del = Swal.fire("Are you sure you want to delete !");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.MemberCardListResidence.indexOf(item);
          self.MemberCardListResidence.splice(Index, 1);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },

    CloseCardDialog(Mode) {
      let self = this;
      self.CardNumber = "";
      if (Mode == "Close") {
        // self.MemberCardList = [];
      }
      self.cardDialog = false;
    },
    CloseCardDialogResidence(Mode) {
      let self = this;
      self.CardNumberResidence = "";
      if (Mode == "Close") {
        // self.MemberCardListResidence = [];
      }
      self.cardDialogResidence = false;
    },
    clearimportdata() {
      let that = this;
      that.dialogImport = true;
      that.listdata = [];
      that.zipArrayData = [];
      that.csv = null;
      that.zip = null;
    },

    getTowerList() {
      let that = this;
      axios
        .get(`${that.url}Tenant/getTowerList`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TowerListArray = response.data.data;
            that.defaultArray = [];
            that.TowerListArray.forEach((element) => {
              let tempData = {
                towerID: element.towerID,
                selectFloor: [],
                defaultFloor: [],
              };
              that.defaultArray.push(tempData);
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    activeCheckDefaultFloor(data) {
      let that = this;

      if (
        that.getRoleAuthFloors.filter((x) => x.defaultFloor == true).length > 0
      ) {
        for (
          let i = 0;
          i <
          that.getRoleAuthFloors.filter((x) => x.defaultFloor == true).length;
          i++
        ) {
          that.getRoleAuthFloors.filter((x) => x.defaultFloor == true)[
            i
          ].defaultFloor = false;
        }
      }

      data.defaultFloor = !data.defaultFloor;
      that.ChooseDefaultFloorBTN = !that.ChooseDefaultFloorBTN;
    },
    GetRoleAuthFloors() {
      let that = this;
      that.LoadingDialog = true;

      axios
        .get(`${that.url}Tenant/GetRoleAuthFloors`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.getRoleAuthFloors = response.data.data;
            for (let i = 0; i < that.getRoleAuthFloors.length; i++) {
              that.getRoleAuthFloors[i].choose = false;
              that.getRoleAuthFloors[i].defaultFloor = false;
            }
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getTenantByTenantID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        tenantID: id,
      };
      axios
        .post(`${that.url}Tenant/getDataTenantByTenantID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.mapEditTower(response.data.data[0]);
            // that.tenantArray = response.data.data;
            // that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    MapTenantByTenantID(id) {
      let that = this;
      that.LoadingDialog = true;
      let data = [];
      if (that.defaultFloorList.length > 0) {
        data = that.defaultFloorList;
      }

      that.tenantID = id;

      // that.towerNameList = "";
      that.floorList = [];
      that.zoneList = [];

      for (let i = 0; i < that.getRoleAuthFloors.length; i++) {
        that.getRoleAuthFloors[i].choose = false;
        that.getRoleAuthFloors[i].defaultFloor = false;
      }

      that.defaultArray = [];

      that.TowerListArray.forEach((element) => {
        let tempData = {
          towerID: element.towerID,
          selectFloor: [],
          defaultFloor: [],
        };
        that.defaultArray.push(tempData);
      });

      data.forEach((value) => {
        let item = that.defaultArray.find((x) => x.towerID == value.towerID);
        if (item != null) {
          that.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).defaultFloor = value.defaultFloor;
          that.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).selectFloor = value.selectFloor;
        }
      });

      // if(data.length != 0){
      //   data
      // }

      // that.towerNameList = data.length != 0 ? data[0].towerID : "";
      if (that.towerNameList != "") {
        that.getDataZoneAndFloorByTowerIDOfMember();
      } else {
        that.LoadingDialog = false;
        that.dialog = true;
      }
    },

    mapEditTower(data) {
      let that = this;

      that.editCheck = true;
      that.towerNameList = "";
      that.floorList = [];
      that.zoneList = [];

      that.tenantID = data.tenantID;

      for (let i = 0; i < that.getRoleAuthFloors.length; i++) {
        that.getRoleAuthFloors[i].choose = false;
        that.getRoleAuthFloors[i].defaultFloor = false;
      }

      that.defaultArray = [];

      that.TowerListArray.forEach((element) => {
        let tempData = {
          towerID: element.towerID,
          selectFloor: [],
          defaultFloor: [],
        };
        that.defaultArray.push(tempData);
      });

      data.defaultArray.forEach((value) => {
        let item = that.defaultArray.find((x) => x.towerID == value.towerID);
        if (item != null) {
          that.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).defaultFloor = value.defaultFloor;
          that.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).selectFloor = value.selectFloor;
        }
      });
      // that.towerNameList =
      //   data.defaultArray.length != 0 ? data.defaultArray[0].towerID : "";
      if (that.towerNameList != "") {
        that.getDataZoneAndFloorByTowerIDOfMember();
      } else {
        that.LoadingDialog = false;
        that.dialog = true;
      }

      // that.LoadingDialog = true;
      // that.dialog = true;

      let setData = {
        tenantID: data.tenantID,
        dataList: that.defaultArray,
      };

      that.defaultFloorList.push(setData);
    },

    getDataZoneAndFloorByTowerIDOfMember() {
      let that = this;
      that.LoadingDialog = true;
      if (that.towerNameList == null) {
        that.getTowerZone = [];
        return;
      }
      let temp = {
        towerID: that.towerNameList,
        tenantID: that.tenant,
      };
      axios
        .post(`${that.url}Tenant/getDataZoneAndFloorByTowerIDOfMember`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.checkbox = [];
            that.checkboxFloor = [];
            that.getTowerZone = response.data.data;
            that.locationMapping =
              that.getTowerZone.accessMapping.locationMapping;
            that.locationFloorMap =
              that.getTowerZone.accessMapping.locationFloorMap;

            that.towerList = that.getTowerZone.towerList;
            that.towerName = that.getTowerZone.towerList.towerID;

            that.floorList = that.getTowerZone.towerList.floorList;
            that.zoneList = that.getTowerZone.towerList.zoneList;
            that.checkbox = that.defaultArray.find(
              (a) => a.towerID == that.towerName
            ).selectFloor;

            that.checkboxFloor = that.defaultArray.find(
              (a) => a.towerID == that.towerName
            ).defaultFloor;
            that.$forceUpdate();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
          that.dialog = true;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    OpenCardDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;
        self.cardNumber = "";
        self.startDateEvent = "";
        self.ExpireDateEvent = "";
        self.cardAccessGroupList = [];
        self.useFace = true;
        self.active = true;
        self.CardAccessDialog = true;
      } else {
        self.getDataCardByCardID(data.cardID);
      }
    },
    OpenCardDialogResidence(module) {
      let self = this;
      if (module == "create") {
        self.CardAccessDialogResidence = true;
      } else {
        self.CardAccessDialogResidence = false;
      }
    },
    getDataCardByCardID(id) {
      let that = this;
      let temp = {
        cardID: id,
      };
      axios
        .post(`${that.url}Card/getDataCardByCardID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.BindingEditData(response.data.data);
            // that.tenantArray = response.data.data;
            // that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      self.cardID = data.cardID;
      self.cardNumber = data.cardNumber;
      self.startDateEvent =
        data.startDate == null ? "" : self.ConvertDate(data.startDate);
      self.ExpireDateEvent =
        data.expiredDate == null ? "" : self.ConvertDate(data.expiredDate);
      self.cardAccessGroupList = data.cardsAccessGroups;
      self.cardAccessGroupListTemp = data.cardsAccessGroups;
      self.tagGroupName = data.tagGroupName;
      self.useFace = data.useFace;
      self.active = data.active;
      self.CardAccessDialog = true;
    },
    ConvertDate(value) {
      // console.log(value);
      if (value != "") {
        return value.split("T")[0];
      }
      // let that = this;
      // that.cardAccessGroupList = that.paginate(that.cardAccessGroupListTemp, 5, value);
    },
    addCard() {
      let that = this;
      // if (that.checkVakidates()) {
      //   Swal.fire({
      //     icon: "warning",
      //     title: "กรุณากรอกข้อมูลให้ครบถ้วน",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      //   return;
      // }
      that.LoadingDialog = true;

      if (!that.EditStatus) {
        let temp = {
          cardNumber: that.cardNumber,
          startDate: that.startDateEvent,
          expiredDate: that.ExpireDateEvent,
          useFace: that.useFace,
          cardAccessGroupList: that.cardAccessGroupList,
          active: that.active,
        };
        axios
          .post(`${that.url}Card/addCard`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            // that.page = 1 ;
            that.getCardList();
            that.CardAccessDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          cardID: that.cardID,
          cardNumber: that.cardNumber,
          startDate: that.startDateEvent,
          expiredDate: that.ExpireDateEvent,
          useFace: that.useFace,
          cardAccessGroupList: that.cardAccessGroupList,
          active: that.active,
        };
        axios
          .post(`${that.url}Card/editCard`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
              that.getMemberByPersonID(that.personID);
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getCardList();
            that.CardAccessDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },
    GetCardsAccessGroup() {
      let that = this;
      that.LoadingDialog = true;

      axios
        .get(`${that.url}Card/getCardsAccessGroup`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.getCardsAccessGroup = response.data.data;


            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    AddDataDropdown(val) {
      let self = this;
      if (val != null) {
        var CheckDuplicate = this.cardAccessGroupList.find(          (x) => x.accessID == val.accessID
        );
        if (CheckDuplicate != null) {
          Swal.fire("Duplicate Data !");
        } else {
          var temp = {
            accessID: val.accessID,
            projectID: val.projectID,
            projectName: val.projectName,
            cardNumber: self.cardNumber,
            towerID: val.towerID,
            towerName: val.towerName,
            tagGroupNo: val.tagrP_NO,
            tagGroupName: val.tagrP_Name,
            active: true,
          };
          self.cardAccessGroupList.push(temp);
          self.cardAccessGroupListTemp = self.cardAccessGroupList;
          self.lengthFloor = Math.ceil(self.cardAccessGroupList.length / 5);
        }
      }
    },
    DeleteDataDropdownCard(item) {
      let self = this;
      // var del = Swal.fire("Are you sure you want to delete !");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.cardAccessGroupList.indexOf(item);
          self.cardAccessGroupList.splice(Index, 1);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  /* background-color: #9AC5F4 !important; */
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
*
  >>> .v-input--selection-controls.v-input--is-disabled:not(
    .v-input--indeterminate
  )
  .v-icon {
  color: lightgrey;
}
</style>
