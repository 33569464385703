<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Transaction Turnstile
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <div>
                <v-card
                  elevation="10"
                  style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
                >
                  <v-row>
                    <v-col cols="12" md="3" class="mt-4">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="startDate"
                            label="Start Date"
                            append-icon="mdi-calendar"
                            outlined
                            hide-details="auto"
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="startDate"
                          @change="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="endDate"
                            label="End Date"
                            append-icon="mdi-calendar"
                            outlined
                            hide-details="auto"
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          @change="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-4">
                      <v-autocomplete
                        :items="tenantArray"
                        item-value="tenantID"
                        item-text="tenantName"
                        label="Tenant"
                        v-model="SearchTenant"
                        required
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-4">
                      <v-autocomplete
                        :items="['Member', 'Visitor']"
                        label="Type"
                        v-model="SearchType"
                        required
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>

                  
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3" lg="3">
                      <v-autocomplete
                        :items="TowerList"
                        item-value="towerID"
                        item-text="towerName"
                        label="Tower"
                        v-model="SearchTower"
                        required
                        clearable
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                      lg="9"
                      style="text-align: end"
                    >
                      <v-btn
                        :color="'blue'"
                        class="ma-2 white--text"
                        @click="SearchTransactionTurnstile()"
                      >
                        Search

                        <v-icon right> mdi-magnify </v-icon>
                      </v-btn>
                      <v-btn
                        color="orange"
                        class="ma-2 white--text"
                        @click="ExportTransactionTurnstile()"
                      >
                        Export CSV
                        <v-icon right>mdi-file-chart-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card
                  elevation="10"
                  style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
                >
                  <v-data-table
                    :headers="headers"
                    :items="DataList"
                    hide-default-footer
                    style="color: #332f2fde"
                    :items-per-page="itemsPerPage"
                    :page.sync="page"
                    single-line
                    hide-details
                  >
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td class="header-table-center-css">
                          {{ no_run + (index + 1) }}
                        </td>
                        <td class="header-table-center-css">
                          {{ ChangeFormatDate(item.transacDatetime) }}
                        </td>
                        <td class="header-table-center-css">
                          {{ item.name }}
                        </td>
                        <td class="header-table-center-css">
                          {{ item.transactionType }}
                        </td>
                        <td class="header-table-center-css">
                          {{ item.tenantName }}
                        </td>
                        <td class="header-table-center-css">
                          {{ item.towerName }}
                        </td>
                        <td class="header-table-center-css">
                          {{ item.projectName }}
                        </td>
                        <td class="header-table-center-css">
                          {{ item.terminalPosition == 0 ? "Entry" : "Exit" }}
                        </td>
                        <td class="header-table-center-css">
                          {{ item.eventName }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <br />
                  <v-row class="mt-5">
                    <v-col cols="12" md="4"></v-col>
                    <v-col cols="12" md="3">
                      <v-pagination
                        v-model="page"
                        :total-visible="20"
                        :length="pageCount"
                        @input="ChangePage(page)"
                      ></v-pagination>
                    </v-col>
                    <v-col cols="12" md="3"></v-col>
                    <v-col cols="12" md="2">
                      <v-autocomplete
                        dense
                        solo
                        label="10/page"
                        v-model="itemsPerPage"
                        @input="ChangePerPage(itemsPerPage)"
                        :items="items"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "TransactionTurnstile",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      ColorTab: "#126496",
      ColorText: "#126496",

      DataList: [],
      DataListVms: [],
      tenantArray: [],
      TowerList: [],
      menu: false,
      menu1: false,
      modal: false,
      menu2: false,

      startDate: "",
      endDate: "",
      SearchTenant: null,
      SearchType: null,
      SearchTower: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "Datetime",
          align: "center",
          sortable: false,
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
        },
        {
          text: "Type",
          align: "center",
          sortable: false,
        },
        {
          text: "Tenant",
          align: "center",
          sortable: false,
        },
        {
          text: "Tower",
          align: "center",
          sortable: false,
        },
        {
          text: "ProjectSite",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "EventName",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  async mounted() {
    let self = this;
    await self.getTransactionTurnstile();
    await self.getTennant();
    await self.getTower();
  },

  methods: {
    getTransactionTurnstile() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        tenantID: self.SearchTenant,
        SearchType: self.SearchType,
        startDate: self.startDate,
        endDate: self.endDate,
        towerID: self.SearchTower,
      };
      axios
        .post(`${self.url}TransactionTurnstile/getTransactionTurnstile`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.DataLisVms = response.data.data.listData.filter(
              (x) => x.transactionType == "Visitor"
            );
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        tenantID: self.SearchTenant,
        SearchType: self.SearchType,
        startDate: self.startDate,
        endDate: self.endDate,
        towerID: self.SearchTower,
      };
      axios
        .post(`${self.url}TransactionTurnstile/getTransactionTurnstile`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        tenantID: self.SearchTenant,
        SearchType: self.SearchType,
        startDate: self.startDate,
        endDate: self.endDate,
        towerID: self.SearchTower,
      };
      axios
        .post(`${self.url}TransactionTurnstile/getTransactionTurnstile`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchTransactionTurnstile() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        tenantID: self.SearchTenant,
        SearchType: self.SearchType,
        startDate: self.startDate,
        endDate: self.endDate,
        towerID: self.SearchTower,
      };
      axios
        .post(`${self.url}TransactionTurnstile/getTransactionTurnstile`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ExportTransactionTurnstile() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        tenantID: self.SearchTenant,
        SearchType: self.SearchType,
        startDate: self.startDate,
        endDate: self.endDate,
        towerID: self.SearchTower,
      };
      axios
        .post(
          `${self.url}TransactionTurnstile/exportTransactionTurnstile`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
            var tempdata = response.data.data;
            const blob = self.base64ToBlob(tempdata, "text/csv;charset=utf-8;");
            const linkSource = URL.createObjectURL(blob);
            var link = document.createElement("a");
            var date = new Date();
            link.download =
              "TransactionTurnstileReport_" +
              date.toLocaleString("th-TH") +
              ".csv";
            link.href = linkSource;
            link.click();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getTennant() {
      let self = this;
      await axios
        .get(`${self.url}Tenant/getDataTenant`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.tenantArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getTower() {
      let self = this;
      await axios
        .get(`${self.url}Tower/getTower`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TowerList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },

    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}
.header-table-center-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
