<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Manage Residence Room
          <v-icon> mdi-home-account </v-icon>
        </div>

        <v-card-text>
          <v-card elevation="10" style="padding: 2%; margin-bottom: 2%">
            <v-row>
              <v-col cols="12" md="2" class="pt-5">
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Start Date"
                      append-icon="mdi-calendar-month"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @change="menuStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" class="pt-5">
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="End Date"
                      append-icon="mdi-calendar-month"
                      outlined
                      hide-details="auto"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @change="menuEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="pt-5">
                <v-text-field
                  dense
                  hide-details="auto"
                  v-model="search"
                  outlined
                  placeholder="Search"
                  v-on:keyup.enter="SearchResidence(search)"
                  placeholder-color="#ACACAC"
                  prepend-inner-icon="mdi-magnify"
                  color="#126496"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" lg="2">
                <v-select
                  :items="['Active', 'InActive']"
                  label="Status"
                  v-model="activeSearch"
                  required
                  clearable
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" lg="6"></v-col>
              <v-col cols="12" md="4" style="text-align: right">
                <v-btn
                  color="blue"
                  class="ma-2 white--text"
                  style="margin-top: auto; margin-bottom: auto"
                  @click="getDataResidence()"
                >
                  Search
                  <v-icon right> mdi-magnify </v-icon>
                </v-btn>
                <v-btn
                  v-if="permissionsPage.create"
                  color="pink"
                  class="ma-2 white--text"
                  @click="openDialog('create', null)"
                >
                  Create
                  <v-icon right> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="10" style="padding: 2%; margin-bottom: 2%">
            <v-data-table
              :headers="headers"
              :items="residenceList"
              hide-default-footer
              style="color: #332f2fde"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              single-line
              hide-details
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                  <td class="header-table-css">{{ item.unitNumber }}</td>
                  <td class="header-table-css">{{ item.houseId }}</td>
                  <td class="header-table-css">{{ item.remark }}</td>
                  <td class="header-table-css">
                    {{ ChangeFormatDate(item.createTime) }}
                  </td>
                  <td
                    style="
                      padding-bottom: 5px;
                      display: flex;
                      flex-direction: column-reverse;
                      align-items: center;
                    "
                  >
                    <v-btn
                      :color="GetColor(item.active)"
                      rounded
                      class="text-capitalize"
                      depressed
                      style="width: 150px"
                    >
                      <span :style="{ color: GetColorText(item.active) }">{{
                        item.active == true ? "Active" : "InActive"
                      }}</span>
                    </v-btn>
                  </td>
                  <td style="text-align: center; min-width: 150px">
                    <v-btn
                      v-if="permissionsPage.update"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="orange"
                      @click="openDialog('edit', item)"
                    >
                      <v-icon dark> mdi-pencil-outline </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="permissionsPage.delete"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="red"
                      @click="
                        inActiveMemberByPersonID(item.personID);
                        disabled;
                      "
                    >
                      <v-icon dark>mdi-trash-can-outline </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <br />
            <v-row class="mt-2">
              <v-col cols="12" md="4"></v-col>
              <v-col cols="12" md="3">
                <v-pagination
                  v-model="page"
                  :total-visible="20"
                  :length="pageCount"
                  @input="ChangePage(page)"
                ></v-pagination>
              </v-col>
              <v-col cols="12" md="3"></v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  solo
                  label="10/page"
                  v-model="itemsPerPage"
                  @input="ChangePerPage(itemsPerPage)"
                  :items="items"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="editDialog" persistent max-width="1100px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="!editStatus" class="text-h5">Create Residence Room</span>
          <span v-else class="text-h5">Edit Residence Room</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col v-if="editStatus" cols="12" sm="2" md="2">
              <v-text-field
                label="Residence ID"
                v-model="residenceID"
                type="number"
                disabled
                readonly
              ></v-text-field>
            </v-col>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Unit number*"
                  v-model="unitNumber"
                  :rules="[rules.required]"
                  ref="ref_unitNumber"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="House No.*"
                  v-model="houseId"
                  :rules="[rules.required]"
                  ref="ref_houseId"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  label="Remark*"
                  v-model="remark"
                  :rules="[rules.required]"
                  ref="ref_remark"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-switch
                  v-model="activeSwitch"
                  hide-details
                  inset
                  :label="activeSwitch ? 'Active' : 'InActive'"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <template>
                  <v-card class="mx-auto">
                    <v-card-title class="white--text primary darken-4">
                      Tower Name :
                      <v-spacer></v-spacer>
                      <v-autocomplete
                        v-model="towerNameList"
                        :items="towerListArray"
                        clearable
                        hide-details
                        hide-selected
                        item-text="towerName"
                        item-value="towerID"
                        solo
                        @change="getDataZoneAndFloorByTowerID()"
                      >
                      </v-autocomplete>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="border: outset; width: unset; border-width: thick"
                    >
                      <table v-if="zoneList.length > 0" style="width: 100%">
                        <thead>
                          <tr>
                            <th class="text-start" style="color: #000000">
                              ชั้น/โซน
                            </th>
                            <th
                              class="text-start"
                              style="padding: 5px; color: #000000"
                              v-for="itemZ in zoneList"
                              :key="itemZ.id"
                            >
                              {{ itemZ.name }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in floorList" :key="item.id">
                            <td style="color: #000000; text-align: center">
                              {{ item.name }}
                            </td>
                            <td v-for="items in zoneList" :key="items.id">
                              <div
                                style="
                                  display: flex;
                                  justify-content: left;
                                  align-items: center;
                                "
                              >
                                <v-checkbox
                                  style="
                                    display: grid;
                                    justify-content: space-around;
                                  "
                                  v-model="checkbox"
                                  @click="
                                    checkboxClick(
                                      CheckBoxData(item.id, items.id)
                                    )
                                  "
                                  :value="CheckBoxData(item.id, items.id)"
                                  :disabled="ZoneWithFloor(item.id, items.id)"
                                ></v-checkbox>
                                <v-btn
                                  v-if="
                                    !ZoneWithFloor(item.id, items.id) &&
                                    !checkButton(
                                      CheckBoxData(item.id, items.id)
                                    )
                                  "
                                  @click="
                                    setDefaultFloor(
                                      CheckBoxData(item.id, items.id),
                                      true
                                    )
                                  "
                                  v-model="checkboxFloor"
                                  x-small
                                  style="
                                    cursor: pointer;
                                    color: gray;
                                    display: grid;
                                    justify-content: space-around;
                                  "
                                  >Default</v-btn
                                >

                                <v-btn
                                  v-if="
                                    !ZoneWithFloor(item.id, items.id) &&
                                    checkButton(CheckBoxData(item.id, items.id))
                                  "
                                  @click="
                                    setDefaultFloor(
                                      CheckBoxData(item.id, items.id),
                                      false
                                    )
                                  "
                                  v-model="checkboxFloor"
                                  x-small
                                  style="cursor: pointer; color: green"
                                  >Default</v-btn
                                >
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th class="text-start" style="color: #000000">
                              ชั้น/โซน
                            </th>
                            <th
                              class="text-start"
                              style="padding: 5px; color: #000000"
                              v-for="itemZ in zoneList"
                              :key="itemZ.id"
                            >
                              {{ itemZ.name }}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="!editStatus"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addResidence()"
              >
                Create
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="updateResidence()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="closeDialog()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>


<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "ManageRoom",
  data() {
    return {
      url: enurl.apiUrl,
      permissionsPage: [],

      // Residence information
      startDate: "",
      endDate: "",
      search: null,
      activeSearch: null,
      menuStartDate: false,
      menuEndDate: false,
      activeSwitch: true,
      rules: { required: (value) => !!value || "Required." },
      residenceList: [],
      residenceID: null,
      unitNumber: null,
      houseId: null,
      remark: null,

      // Tower information
      residenceAuthFloorArray: [],
      towerListArray: [],
      defaultArray: [],
      towerNameList: "",
      getTowerZone: [],
      tenant: [],
      checkbox: [],
      checkboxFloor: [],
      locationMapping: [],
      locationFloorMap: [],
      towerList: [],
      towername: "",
      floorList: [],
      zoneList: [],
      defaultFloor: [],
      selectFloor: [],

      // Page information
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      items: [10, 15, 20, 50, 100],
      editDialog: false,
      editStatus: false,
      loadingDialog: false,

      headers: [
        { text: "No.", sortable: false, align: "center" },
        { text: "Unit Number", sortable: false, align: "center" },
        { text: "House No.", sortable: false, align: "center" },
        { text: "Remark", sortable: false, align: "center" },
        { text: "Create Time", sortable: false, align: "center" },
        { text: "Active", sortable: false, align: "center" },
        { text: "Action", sortable: false, align: "center" },
      ],
    };
  },
  mounted() {
    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    this.getDataResidence();
    this.getResidenceTowerList();
    this.GetRoleAuthFloors();
  },
  watch: {
    checkbox() {
      let self = this;
      if (self.defaultArray.find((a) => a.towerID == self.towerName) != null) {
        self.defaultArray.find((a) => a.towerID == self.towerName).selectFloor =
          self.checkbox;
      }
    },
  },
  methods: {
    // Residence
    getDataResidence() {
      let self = this;
      self.loadingDialog = true;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      self.executeDatabaseByAxios("Residence/getDataResidence", temp);
      self.loadingDialog = false;
    },
    SearchResidence(data) {
      let self = this;
      self.loadingDialog = true;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeSearch == "Active"
            ? true
            : self.activeSearch == null
            ? null
            : false,
      };
      self.executeDatabaseByAxios("Residence/getDataResidence", temp);
      self.loadingDialog = false;
    },
    addResidence() {
      let self = this;
      self.loadingDialog = true;
      let temp = {
        residenceID: parseInt(self.residenceID),
        unitNumber: self.unitNumber,
        houseId: self.houseId,
        remark: self.remark,
        active: self.activeSwitch,
        residenceAuthFloorArray: self.defaultArray,
      };
      axios
        .post(`${self.url}Residence/addResidence`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
            self.getDataResidence();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
      self.editDialog = false;
      self.loadingDialog = false;
    },
    updateResidence() {
      let self = this;
      self.loadingDialog = true;
      let temp = {
        residenceID: parseInt(self.residenceID),
        unitNumber: self.unitNumber,
        houseId: self.houseId,
        remark: self.remark,
        active: self.activeSwitch,
        residenceAuthFloorArray: self.defaultArray,
      };
      axios
        .post(`${self.url}Residence/updateResidence`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
            self.getDataResidence();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
      self.editDialog = false;
      self.loadingDialog = false;
    },
    getResidenceByResidenceID(id) {
      let self = this;
      self.loadingDialog = true;
      let temp = {
        residenceID: parseInt(id),
      };
      axios
        .post(`${self.url}Residence/getResidenceByResidenceID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.bindingData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            self.loadingDialog = false;
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    bindingData(data) {
      let self = this;
      self.towerNameList = "";
      self.floorList = [];
      self.zoneList = [];
      self.residenceID = data.residenceID;
      self.unitNumber = data.unitNumber;
      self.houseId = data.houseId;
      self.remark = data.remark;
      self.activeSwitch = data.active;
      for (let i = 0; i < self.getRoleAuthFloors.length; i++) {
        self.getRoleAuthFloors[i].choose = false;
        self.getRoleAuthFloors[i].defaultFloor = false;
      }

      self.defaultArray = [];
      self.towerListArray.forEach((element) => {
        let tempData = {
          towerID: element.towerID,
          selectFloor: [],
          defaultFloor: [],
        };
        self.defaultArray.push(tempData);
      });

      data.defaultArray.forEach((value) => {
        let item = self.defaultArray.find((x) => x.towerID == value.towerID);
        if (item != null) {
          self.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).defaultFloor = value.defaultFloor;
          self.defaultArray.find(
            (x) => x.towerID == value.towerID
          ).selectFloor = value.selectFloor;
        }
      });
      self.towerNameList =
        data.defaultArray.length != 0 ? data.defaultArray[0].towerID : "";
      if (self.towerNameList != "") {
        self.getDataZoneAndFloorByTowerID();
      } else {
        self.loadingDialog = false;
      }
    },
    openDialog(mode, data) {
      let self = this;
      if (mode == "create") {
        self.towerNameList = "";
        self.floorList = [];
        self.zoneList = [];
        self.checkbox = "";
        self.checkboxFloor = "";

        self.residenceID = null;
        self.unitNumber = null;
        self.houseId = null;
        self.remark = null;
        self.defaultFloor = [];

        self.editStatus = false;
        self.getResidenceTowerList();
        self.editDialog = true;
      } else {
        self.editStatus = true;
        self.getResidenceByResidenceID(data.residenceID);
        self.editDialog = true;
      }
    },
    closeDialog() {
      let self = this;
      self.editDialog = false;
      self.$refs.ref_unitNumber.reset();
      self.$refs.ref_houseId.reset();
      self.$refs.ref_remark.reset();
    },
    validateResidenceData() {
      if (
        this.unitNumber == "" ||
        this.unitNumber == null ||
        this.houseId == "" ||
        this.houseId == null
      ) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      } else {
        return true;
      }
    },

    // Tower, Floor and Zone setting
    getResidenceTowerList() {
      let self = this;
      self.loadingDialog = true;
      axios
        .get(`${self.url}Residence/getResidenceTowerList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.towerListArray = response.data.data;
            self.defaultArray = [];
            self.towerListArray.forEach((element) => {
              let tempData = {
                towerID: element.towerID,
                selectFloor: [],
                defaultFloor: [],
              };
              self.defaultArray.push(tempData);
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
      self.loadingDialog = false;
    },
    getDataZoneAndFloorByTowerID() {
      let self = this;
      self.loadingDialog = true;
      if (self.towerNameList == null) {
        self.getTowerZone = [];
        return;
      }
      let temp = {
        towerID: self.towerNameList,
      };
      axios
        .post(`${self.url}Tenant/getDataZoneAndFloorByTowerID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.checkbox = [];
            self.checkboxFloor = [];
            self.getTowerZone = response.data.data;
            self.locationMapping =
              self.getTowerZone.accessMapping.locationMapping;
            self.locationFloorMap =
              self.getTowerZone.accessMapping.locationFloorMap;
            self.towerName = self.getTowerZone.towerList.towerID;
            self.floorList = self.getTowerZone.towerList.floorList;
            self.zoneList = self.getTowerZone.towerList.zoneList;
            self.checkbox = self.defaultArray.find(
              (a) => a.towerID == self.towerName
            ).selectFloor;
            self.checkboxFloor = self.defaultArray.find(
              (a) => a.towerID == self.towerName
            ).defaultFloor;
            self.$forceUpdate();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.loadingDialog = false;
          self.editDialog = true;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    setDefaultFloor(value, checked) {
      let that = this;
      if (checked) {
        this.OneFloorInZone(value);
        if (that.checkbox.indexOf(value) != -1) {
          that.checkboxFloor.push(value);
        }
      } else {
        let index = that.checkboxFloor.indexOf(value);
        that.checkboxFloor.splice(index, 1);
      }
    },
    checkboxClick(value) {
      let that = this;

      that.checkboxFloor.forEach((x) => {
        if (that.checkbox.find((a) => a == x) == null) {
          let index = that.checkboxFloor.indexOf(value);
          that.checkboxFloor.splice(index, 1);
        }
      });
    },
    checkButton(value) {
      let that = this;
      let defaultFloor = that.checkboxFloor.find((x) => x == value);
      let selectFloor = that.checkbox.find((x) => x == value);
      if (defaultFloor != null && selectFloor != null) {
        return true;
      }

      return false;
    },
    CheckBoxData(floorID, zoneID) {
      let temp = "";
      let dataCheck = this.locationFloorMap.find(
        (x) => x.floorID == floorID && x.zoneID == zoneID
      );
      if (dataCheck != null) {
        temp = dataCheck.locationID;
        return temp;
      }
      return temp;
    },
    ZoneWithFloor(floorID, zoneID) {
      let dataCheck = this.locationFloorMap.find(
        (x) => x.floorID == floorID && x.zoneID == zoneID
      );
      if (dataCheck != null) {
        return false;
      }

      return true;
    },
    OneFloorInZone(value) {
      let that = this;
      let mainZoneID = this.locationMapping.find(
        (x) => x.locationID == value && x.towerID == this.towerName
      ).zoneID;

      let defaultData = this.defaultArray.find(
        (f) => f.towerID == this.towerName
      ).defaultFloor;

      defaultData.forEach((item) => {
        let findZoneDuplicate = this.locationMapping.find(
          (x) => x.locationID == item && x.towerID == this.towerName
        ).zoneID;
        if (mainZoneID == findZoneDuplicate) {
          if (that.checkbox.find((a) => a == value) != null) {
            let index = that.checkboxFloor.indexOf(item);
            that.checkboxFloor.splice(index, 1);
          }
        }
      });
    },
    GetRoleAuthFloors() {
      let that = this;
      that.loadingDialog = true;

      axios
        .get(`${that.url}Tenant/GetRoleAuthFloors`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.getRoleAuthFloors = response.data.data;
            for (let i = 0; i < that.getRoleAuthFloors.length; i++) {
              that.getRoleAuthFloors[i].choose = false;
              that.getRoleAuthFloors[i].defaultFloor = false;
            }
            that.loadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.loadingDialog = false;
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    // Additional function
    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },
    ChangePage(value) {
      let self = this;
      self.loadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
      };
      self.executeDatabaseByAxios("Residence/getDataResidence", temp);
      self.loadingDialog = false;
    },
    ChangePerPage(value) {
      let self = this;
      self.loadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
      };
      self.executeDatabaseByAxios("Residence/getDataResidence", temp);
      self.loadingDialog = false;
    },
    executeDatabaseByAxios(databaseName, data) {
      let self = this;
      axios
        .post(`${self.url}${databaseName}`, data)
        .then(function (response) {
          if (response.data.status == 0) {
            self.residenceList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.loadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
  },
};
</script>


<style>
.v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
.v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.header-table-css {
  text-align: center;
  cursor: pointer;
}
</style>