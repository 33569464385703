<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Interface SAP Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" class="pt-5">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    v-on:keyup.enter="searchInterfaceSAPList(search)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getInterfaceSAPList()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="permissionsPage.create"
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenEditDialog('create', null)"
                    >
                      Create
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="interfaceSAPList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.name_Type }}</td>
                    <td class="header-table-css">{{ item.device_name }}</td>
                    <td style="text-align: center; min-width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenEditDialog('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="EditDialog" persistent max-width="900px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="EditStatus" class="text-h5">Edit Interface SAP</span>
          <span v-else class="text-h5">Create Interface SAP</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="EditStatus == true">
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Log ID*"
                  v-model="log_ID"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Log Type ID"
                  v-model="log_type_id"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Log Type ID"
                  v-model="log_type_id"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Name type*"
                  v-model="name_Type"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Device name"
                  v-model="device_name"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Document ID"
                  v-model="document_id"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Contract No."
                  v-model="contract_No"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Document Header Text"
                  v-model="document_Header_Text"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Document Type"
                  v-model="document_Type"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Company code"
                  v-model="company_Code"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Currency(THB)"
                  v-model="currency_THB"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Branch number"
                  v-model="branch_number"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Posting key"
                  v-model="posting_Key"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="GL account"
                  v-model="gL_Account"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Section type"
                  v-model="saction_Type"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Tax code"
                  v-model="tax_Code"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Profit center"
                  v-model="profit_Center"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Cost Center"
                  v-model="cost_Center"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Assignment"
                  v-model="assignment"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Text detail"
                  v-model="text_Detail"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Reference key1"
                  v-model="reference_Key_1"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Reference key2"
                  v-model="reference_Key_2"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Payment method"
                  v-model="payment_Method"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addInterfaceSAP()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addInterfaceSAP()"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="EditDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
  <script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "ManageInterfaceSAP",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      permissionsPage: [],

      search: "",
      EditDialog: false,
      EditStatus: false,

      // Interface SAP information
      interfaceSAPList: [],
      log_type_id: null,
      name_Type: null,
      device_name: null,
      document_id: null,
      contract_No: null,
      document_Header_Text: null,
      document_Type: null,
      company_Code: null,
      currency_THB: null,
      branch_number: null,
      posting_Key: null,
      gL_Account: null,
      saction_Type: null,
      tax_Code: null,
      profit_Center: null,
      cost_Center: null,
      assignment: null,
      text_Detail: null,
      reference_Key_1: null,
      reference_Key_2: null,
      payment_Method: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Device",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    self.getInterfaceSAPList();
  },

  methods: {
    getInterfaceSAPList() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
      };
      axios
        .post(`${self.url}InterfaceSAP/getInterfaceSAPList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.interfaceSAPList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
      };
      axios
        .post(`${self.url}InterfaceSAP/getInterfaceSAPList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.interfaceSAPList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
      };
      axios
        .post(`${self.url}InterfaceSAP/getInterfaceSAPList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.interfaceSAPList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    searchInterfaceSAPList(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
      };
      axios
        .post(`${self.url}InterfaceSAP/getInterfaceSAPList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.interfaceSAPList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    addInterfaceSAP() {
      let that = this;
      
      if (that.validateFilledData()) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูล Name type ให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      that.LoadingDialog = true;
      let temp = {
        log_type_id: parseInt(that.log_type_id),
        name_Type: that.name_Type,
        device_name: that.device_name,
        document_id: that.document_id,
        contract_No: that.contract_No,
        document_Header_Text: that.document_Header_Text,
        document_Type: that.document_Type,
        company_Code: that.company_Code,
        currency_THB: that.currency_THB,
        branch_number: that.branch_number,
        posting_Key: that.posting_Key,
        gL_Account: that.gL_Account,
        saction_Type: that.saction_Type,
        tax_Code: that.tax_Code,
        profit_Center: that.profit_Center,
        cost_Center: that.cost_Center,
        assignment: that.assignment,
        text_Detail: that.text_Detail,
        reference_Key_1: that.reference_Key_1,
        reference_Key_2: that.reference_Key_2,
        payment_Method: that.payment_Method,
      };

      if (!that.EditStatus) {
        axios
          .post(`${that.url}InterfaceSAP/addInterfaceSAPList`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.page = 1;
            that.getInterfaceSAPList();
            that.EditDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        temp.log_ID = parseInt(that.log_ID);
        axios
          .post(`${that.url}InterfaceSAP/editInterfaceSAPList`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.getInterfaceSAPList();
            that.EditDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    validateFilledData() {
      let that = this;
      if (that.name_Type == null) {
        return true;
      } else {
        return false;
      }
    },

    OpenEditDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;
        self.EditDialog = true;

        self.log_type_id = null;
        self.name_Type = null;
        self.device_name = null;
        self.document_id = null;
        self.contract_No = null;
        self.document_Header_Text = null;
        self.document_Type = null;
        self.company_Code = null;
        self.currency_THB = null;
        self.branch_number = null;
        self.posting_Key = null;
        self.gL_Account = null;
        self.saction_Type = null;
        self.tax_Code = null;
        self.profit_Center = null;
        self.cost_Center = null;
        self.assignment = null;
        self.text_Detail = null;
        self.reference_Key_1 = null;
        self.reference_Key_2 = null;
        self.payment_Method = null;
      } else {
        self.BindingEditData(data);
      }
    },

    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      self.EditDialog = true;

      self.log_ID = data.log_ID;
      self.log_type_id = data.log_type_id;
      self.name_Type = data.name_Type;
      self.device_name = data.device_name;
      self.document_id = data.document_id;
      self.contract_No = data.contract_No;
      self.document_Header_Text = data.document_Header_Text;
      self.document_Type = data.document_Type;
      self.company_Code = data.company_Code;
      self.currency_THB = data.currency_THB;
      self.branch_number = data.branch_number;
      self.posting_Key = data.posting_Key;
      self.gL_Account = data.gL_Account;
      self.saction_Type = data.saction_Type;
      self.tax_Code = data.tax_Code;
      self.profit_Center = data.profit_Center;
      self.cost_Center = data.cost_Center;
      self.assignment = data.assignment;
      self.text_Detail = data.text_Detail;
      self.reference_Key_1 = data.reference_Key_1;
      self.reference_Key_2 = data.reference_Key_2;
      self.payment_Method = data.payment_Method;
    },
  },
};
</script>
  <style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
  