<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Adjust Transaction Payment
          <v-icon>mdi-lead-pencil</v-icon>
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar-month"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar-month"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-text-field
                    label="Tax Number"
                    append-icon="mdi-text-search"
                    v-model="trn_tax_no"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-text-field
                    label="Ticket Number"
                    append-icon="mdi-card-search-outline"
                    v-model="trn_ticket_no"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-text-field
                    label="Car Number"
                    append-icon="mdi-car-outline"
                    v-model="trn_car_no"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style="text-align: end"
                >
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getTransacExitAndKioskPayment()"
                    >
                      Search
                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-data-table
                :headers="headers"
                :items="transactionList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.trn_date }}</td>
                    <td class="header-table-css">{{ item.trn_tax_no }}</td>
                    <td class="header-table-css">{{ item.trn_ticket_no }}</td>
                    <td class="header-table-css">{{ item.trn_car_no }}</td>
                    <td style="text-align: center; min-width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="openEditDialog(item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        disabled
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="editDialog" persistent max-width="900px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Adjust Transaction Payment</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  label="Transaction ID"
                  v-model="trn_log_ID_edit"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Transaction date"
                  v-model="trn_date_edit"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Ticket number"
                  v-model="trn_ticket_no_edit"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  label="Car license plate"
                  v-model="trn_car_no_edit"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Current total value"
                  v-model="trn_total"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Adjust value*"
                  v-model="trn_total_adj_edit"
                  :rules="[rules.required]"
                  hint="Example: -50, 20"
                  ref="ref_total_adj"
                  type="number"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Total*"
                  v-model="trn_total_net_adj_edit"
                  :rules="[rules.required]"
                  ref="ref_total_net_adj"
                  type="number"
                  required
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Remark"
                  v-model="trn_remark_adj_edit"
                  ref="ref_remark_adj"
                  required
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="adjustTransactionPayment()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="closeDialog()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "AdjustTransactionPayment",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      permissionsPage: [],

      // Table
      no_run: null,
      pageCount: 0,
      page: 1,
      items: [10, 15, 20, 50, 100],
      itemsPerPage: 10,
      rules: { required: (value) => !!value || "Required" },

      // Transaction information
      transactionList: [],
      startDate: null,
      endDate: null,
      menuStartDate: false,
      menuEndDate: false,
      trn_log_ID: null,
      trn_date: null,
      trn_ticket_no: null,
      trn_tax_no: null,
      trn_car_no: null,
      trn_total: null,

      trn_log_ID_edit: null,
      trn_date_edit: null,
      trn_ticket_no_edit: null,
      trn_tax_no_edit: null,
      trn_car_no_edit: null,
      trn_total_adj_edit: null,
      trn_total_net_adj_edit: null,
      trn_remark_adj_edit: null,

      // Edit Dialog
      loadingDialog: false,
      editDialog: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: "No", align: "left", sortable: false },
        { text: "Transaction date", align: "left", sortable: false },
        { text: "Tax number", align: "left", sortable: false },
        { text: "Ticket number", align: "left", sortable: false },
        { text: "License plate", align: "left", sortable: false },
        { text: "Action", align: "center", sortable: false },
      ];
    },
  },

  mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    self.getTransacExitAndKioskPayment();
  },

  methods: {
    getTransacExitAndKioskPayment() {
      let self = this;
      self.page = 1;
      self.loadingDialog = true;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        startDate: self.startDate,
        endDate: self.endDate,
        trn_ticket_no: self.trn_ticket_no,
        trn_tax_no: self.trn_tax_no,
        trn_car_no: self.trn_car_no,
      };
      axios
        .post(
          `${self.url}TransactionCarpark/getTransacExitAndKioskPayment`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.transactionList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    adjustTransactionPayment() {
      let self = this;
      self.page = 1;

      if (
        self.trn_total_adj_edit == null ||
        self.trn_total_adj_edit == "" ||
        self.trn_total_net_adj_edit == null ||
        self.trn_total_net_adj_edit == ""
      ) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      } else {
        //continue
      }

      self.loadingDialog = true;
      let temp = {
        trn_tax_no: self.trn_tax_no_edit,
        trn_total_adj: parseInt(self.trn_total_adj_edit),
        trn_total_net_adj: parseInt(self.trn_total_net_adj_edit),
        trn_remark_adj: self.trn_remark_adj_edit,
      };
      axios
        .post(`${self.url}TransactionCarpark/adjustTransactionPayment`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.loadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.getTransacExitAndKioskPayment();
          self.editDialog = false;
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    openEditDialog(data) {
      let self = this;
      self.editDialog = true;

      self.trn_log_ID_edit = data.trn_log_ID;
      self.trn_date_edit = data.trn_date;
      self.trn_ticket_no_edit = data.trn_ticket_no;
      self.trn_tax_no_edit = data.trn_tax_no;
      self.trn_car_no_edit = data.trn_car_no;
      self.trn_total = data.trn_total;
      self.trn_total_adj_edit = data.trn_total_adj;
      self.trn_total_net_adj_edit = data.trn_total_net_adj;
      self.trn_remark_adj_edit = data.trn_remark_adj;
    },
    closeDialog() {
      let self = this;
      self.editDialog = false;
      self.$refs.ref_total_adj.reset();
      self.$refs.ref_total_net_adj.reset();
      self.$refs.ref_remark_adj.reset();
    },
    ChangePage(value) {
      let self = this;
      self.loadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        startDate: self.startDate,
        endDate: self.endDate,
        trn_ticket_no: self.trn_ticket_no,
        trn_tax_no: self.trn_tax_no,
        trn_car_no: self.trn_car_no,
      };
      axios
        .post(
          `${self.url}TransactionCarpark/getTransacExitAndKioskPayment`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.transactionList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.loadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        startDate: self.startDate,
        endDate: self.endDate,
        trn_ticket_no: self.trn_ticket_no,
        trn_tax_no: self.trn_tax_no,
        trn_car_no: self.trn_car_no,
      };
      axios
        .post(
          `${self.url}TransactionCarpark/getTransacExitAndKioskPayment`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            self.transactionList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
  